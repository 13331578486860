import React from 'react';
import Button from '@fvillard/reactbutton';
import Input from '../../modules/react-input/src/index.js';
import '../../styles/layouts/register.scss';



import { connect } from 'react-redux'

class RegisterLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {
				Email: 'fvillard@nemesix.com',
				Password: 'pass',
				Name: 'Villard',
				Surname: 'Francois',
				ShowedStock: 0.8,
				Role: 0,
			},
			error: {
				Email: null,//'',
				Password: null,//'',
				Name: null,//'',
				Surname: null,//'',
			}
		};
	}
	onChange = (id, value, error) => {
		var item = this.state.item;
		var item_error = this.state.error;
		item[id] = value;
		item_error[id] = error;
		this.setState({item: item, error: item_error})
	}
	onSave = () => {
		if(Object.values(this.state.error).find(field => field !== null) === undefined){
			const action = { type: "register", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
			alert('Account created succefully !')
		}
	}
	render() {
		//var that = this;
		return(
			<div className='register'>
				<div className='register-formBox'>
					<h1>Sign up</h1>
					<Input
						id='Email'
						type='email'
						label='Email'
						value={this.state.item.Email}
						onChange={this.onChange}
						error=''
						required
					/>
					<Input
						id='Password'
						type='password'
						label='Password'
						value={this.state.item.Password}
						onChange={this.onChange}
						error=''
						required
					/>
					<Input
						id='Name'
						label='Name'
						value={this.state.item.Name}
						onChange={this.onChange}
						error=''
						required
					/>
					<Input
						id='Surname'
						label='Surname'
						value={this.state.item.Surname}
						onChange={this.onChange}
						error=''
						required
					/>
					<Button 
						style={{marginTop: '10px'}}
						label='Confirm'
						onClick={this.onSave}
					/>
				</div>
			</div>
		);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(RegisterLayout);
