import React from 'react';
import '../../styles/layouts/materialsTypeAdmin/subnav.scss';
import { FaMinus, FaFileSignature, FaFileUpload, FaClone } from 'react-icons/fa';
import { connect } from 'react-redux'

class MaterialsTypeSubNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		var that = this;
			return(
				<div className='subnav'>
					<div className='subnav-groups'>
						<div className='subnav-groups-group'>
							<div className='subnav-groups-group-items'>
								{
									this.props.pageState.onEdit ? (
										<div className='subnav-views-group-item' onClick={this.props.onSave !== undefined ? this.props.onSave : function(){}}>
											<div className='subnav-groups-group-items-item-icon'>
												<FaFileUpload className='subnav-groups-group-items-item-icon-element' />
											</div>
											<div className='subnav-groups-group-items-item-title'>
												Enregistrer
											</div>
										</div>
									) : (
										<div className={this.props.pageState.item.id > 0 ? 'subnav-groups-group-items-item' : 'subnav-groups-group-items-item subnav-groups-group-items-item--disable'} onClick={this.props.onEdit !== undefined ? this.props.onEdit : function(){}}>
											<div className='subnav-groups-group-items-item-icon'>
												<FaFileSignature className='subnav-groups-group-items-item-icon-element' />
											</div>
											<div className='subnav-groups-group-items-item-title'>
												Modifier
											</div>
										</div>
									)
								}
								{
									this.props.pageState.onCopy ? (
										<div className='subnav-views-group-item' onClick={this.props.onCancelCopy !== undefined ? this.props.onCancelCopy : function(){}}>
											<div className='subnav-groups-group-items-item-icon'>
												<FaFileUpload className='subnav-groups-group-items-item-icon-element' />
											</div>
											<div className='subnav-groups-group-items-item-title'>
												Valider
											</div>
										</div>
									) : (
										<div className={this.props.pageState.item.id > 0 && this.props.pageState.onEdit ? 'subnav-groups-group-items-item' : 'subnav-groups-group-items-item subnav-groups-group-items-item--disable'} onClick={this.props.onCopy !== undefined ? this.props.onCopy : function(){}}>
											<div className='subnav-groups-group-items-item-icon'>
												<FaClone className='subnav-groups-group-items-item-icon-element' />
											</div>
											<div className='subnav-groups-group-items-item-title'>
												Copier
											</div>
										</div>
									)
								}
							</div>
							
							<div className='subnav-groups-group-title'>
									Material Type
							</div> 
						</div>
					</div>
					<div className='subnav-views'>
						<div className='subnav-views-group'>
							<div className={this.props.pageState.view === 'list' ? 'subnav-views-group-item subnav-views-group-item--active' : 'subnav-views-group-item'} onClick={this.props.onChangeView !== undefined ? function(){that.props.onChangeView('list')} : function(){}}>
								<div className='subnav-views-group-item-icon'>
									<FaMinus className='subnav-views-group-item-icon-element' />
								</div>
								<div className='subnav-views-group-item-title'>
									Liste
								</div>
							</div>
							<div className={this.props.pageState.view === 'split' ? 'subnav-views-group-item subnav-views-group-item--active' : 'subnav-views-group-item'} onClick={this.props.onChangeView !== undefined ? function(){that.props.onChangeView('split')} : function(){}}>
								<div className='subnav-views-group-item-icon'>
									<FaMinus className='subnav-views-group-item-icon-element' />
								</div>
								<div className='subnav-views-group-item-title'>
									Combiné
								</div>
							</div>
							<div className={this.props.pageState.view === 'item' ? 'subnav-views-group-item subnav-views-group-item--active' : 'subnav-views-group-item'} onClick={this.props.onChangeView !== undefined ? function(){that.props.onChangeView('item')} : function(){}}>
								<div className='subnav-views-group-item-icon'>
									<FaMinus className='subnav-views-group-item-icon-element' />
								</div>
								<div className='subnav-views-group-item-title'>
									Détailé
								</div>
							</div>
						</div>
					</div>
        		</div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(MaterialsTypeSubNav);
