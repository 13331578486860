import React from 'react';
import Table from '../../modules/react-table/src/index.js';
import '../../styles/layouts/materialsTypeAdmin/materialsTypeAdmin.scss';
import SubNav from './subnav';
import Input from '../../modules/react-input/src/index.js';

import { connect } from 'react-redux'

class MaterialsTypeLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			onCopy: false,
			view: 'split',
			selectedItems: {},
			item: {MaterialTypeID:0, MaterialTypeCode:'', Description:''},
		};
		const action = { type: "get materialsTypes", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action);
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onCopy = () => {
		this.state.onCopy === false && this.setState({onCopy: true})
	}
	onCancelCopy = () => {
		this.state.onCopy && this.setState({onCopy: false})
	}
	onSave = () => {
		if(this.state.item.id === ''){
			const action = { type: "create materialType", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		else{
			const action = { type: "save materialType", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (items) => {
		if(this.state.onEdit){
			if(this.state.onCopy){
				var item = this.state.item;
				item.MaterialTypeCode = Object.values(items)[0].MaterialTypeCode;
				item.Description = Object.values(items)[0].Description;
				this.setState({item: item})
			}
			else{
				if(window.confirm('Do you really discard change ?'))
				{
					this.setState({item: Object.values(items)[0], onEdit: false})
				}
			}
		}
		else{
			this.setState({item: Object.values(items)[0]})
		}
	}
	render(){
			var that = this;
			return(
				<div className='materialsAdmin'>
					<SubNav
						pageState={this.state}
						onEdit={this.onEdit}
						onCopy={this.onCopy}
						onCancelCopy={this.onCancelCopy}
						onSave={this.onSave}
						onCancel={this.onCancel}
						onChangeView={this.onChangeView}
					/>
					<div className={'users-content users-content--'+this.state.view}>
						<div className={'users-content users-content--'+this.state.view+'-item'}>
						<Input
							id='test'
							label='Test'
							error=''
							value={this.state.item.test}
							type='percent'
							disabled={!this.state.onEdit}
							required={true}
							onChange={this.onChange}
						/>
						<Input
							id='MaterialTypeCode'
							label='MaterialTypeCode'
							error=''
							value={this.state.item.MaterialTypeCode}
							disabled={!this.state.onEdit}
							required={true}
							onChange={this.onChange}
						/>
						<Input
							id='Description'
							label='Description'
							error=''
							value={this.state.item.Description}
							disabled={!this.state.onEdit}
							required={true}
							onChange={this.onChange}
						/>
						</div>
						<div className={'users-content users-content--'+this.state.view+'-list'}>
							<Table
								headers={
									[
										{
											label: 'MaterialTypeCode',
											field: 'MaterialTypeCode',
											width: '150px',
										},
										{
											label: 'Description',
											field: 'Description',
											width: '200px',
										}
									]
								}
								data={
									Object.values(this.props.dataReducer.materialsTypes)
								}
								onSelectionChange={function(selection){
									that.onSelectItem(selection)
								}}
							/>
						</div>
					</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(MaterialsTypeLayout);
