import React from 'react';
import '../../styles/layouts/article.scss';
import featureIcon from '../../assets/img/feature.svg';
import leftChevronRedIcon from '../../assets/img/leftChevron-red.png';
import Table from '../../modules/react-table/src/index';


import { connect } from 'react-redux'

class Article extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			item: this.props.interfaceReducer.currentPageArgs !== undefined && this.props.interfaceReducer.currentPageArgs !== null && this.props.interfaceReducer.currentPageArgs.item !== undefined ? this.props.interfaceReducer.currentPageArgs.item : null,
		};
	}
	onCloseItem = () => {
		var that = this;
		const clear_action = { type: "CLEAR_CURRENT_PAGE_ARGS", socket: {
			send: false
		}}
		that.props.dispatch(clear_action)
		var header = Object.values(this.props.dataReducer.headers).find(item => item.HeaderNo === this.state.item.HeaderNo)
		const action = { type: "CHANGE_CURRENT_PAGE", value: 'tool-selector', args: {selectedItem: header}, socket: {
			send: false
		}}
		this.props.dispatch(action)
	}
	render() {
		var that = this;
		var header = Object.values(this.props.dataReducer.headers).find(item => item.HeaderNo === this.state.item.HeaderNo)
		var article = this.state.item
		const images_data = header.headersPositions.filter(headerPosition => headerPosition.icon.Type === 'IMAGE');
		const icons_data = header.headersPositions.filter(headerPosition => headerPosition.icon.Type === 'ICON');
		const drawing_data = header.headersPositions.filter(headerPosition => headerPosition.icon.Type === 'DRAWING');
		
			return(
				<div className='article' style={{maxWith: '100%'}}>
					<div className='article-subnav'>
						<div className='article-subnav-left-box'>
							<div className='article-subnav-return-box' onClick={this.state.item !== null ? this.onCloseItem : function(){}}>
								<img className='article-subnav-icon' src={leftChevronRedIcon} alt='' />
								Back
							</div>
						</div>
						<div className='article-subnav-center-box'>

						</div>
						<div className='article-subnav-right-box'>
						</div>
					</div>
					<div className='article-mainWrapper'>
						<div className='article-mainWrapper-headerBox'>
							<div className='article-mainWrapper-headerBox-leftBox'>
								<div className="table">
									<div className='row'>
										<div className='label'>
											Code
										</div>
										<div className='value'>
											{article.ArticleNo}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Thread Type
										</div>
										<div className='value'>
											{article.ThreadType}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Size
										</div>
										<div className='value'>
											{article.d1}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Pitch
										</div>
										<div className='value'>
											{article.P}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Tolerance
										</div>
										<div className='value'>
											{icons_data.find(item => item.position.PositionCode === 'H-TOL') !== undefined ? 
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-TOL').icon.IconID) !== undefined ?
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-TOL').icon.IconID).ShortDescription : ''
											 : ''}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Chamfer
										</div>
										<div className='value'>
										{icons_data.find(item => item.position.PositionCode === 'H-3A') !== undefined ? 
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-3A').icon.IconID) !== undefined ?
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-3A').icon.IconID).ShortDescription : ''
											 : 'Icon not found'}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Flute Form
										</div>
										<div className='value'>
											{icons_data.find(item => item.position.PositionCode === 'H-3B') !== undefined ? 
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-3B').icon.IconID) !== undefined ?
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-3B').icon.IconID).ShortDescription : ''
											 : 'Icon not found'}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Shank
										</div>
										<div className='value'>
										{icons_data.find(item => item.position.PositionCode === 'H-S1') !== undefined ? 
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-S1').icon.IconID) !== undefined ?
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-S1').icon.IconID).ShortDescription : ''
											 : ''}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Cutting Geometry
										</div>
										<div className='value'>
											{header.WorkMaterial_Text1}
										</div>
									</div>
									<div className='row'>
										<div className='label'>
											Coating / Cutting Material
										</div>
										<div className='value'>
										{icons_data.find(item => item.position.PositionCode === 'H-3C') !== undefined ? 
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-3C').icon.IconID) !== undefined ?
											Object.values(this.props.dataReducer.technicalCaracteristics).find(item => item.IconID === icons_data.find(item => item.position.PositionCode === 'H-3C').icon.IconID).ShortDescription : ''
											 : 'Icon not found'}
										</div>
									</div>
								</div>
							</div>
							<div className='article-mainWrapper-headerBox-centerBox'>
								<div className='imgBox'>
									<img src={images_data[0] !== undefined && process.env.PUBLIC_URL + '/IMAGE/'+images_data[0].icon.FileName+'.'+images_data[0].icon.IconFileExtension} alt=''/>
								</div>
								{
									(images_data.length > 1 &&
										<div className='imgBox'>
											<img src={process.env.PUBLIC_URL + '/IMAGE/'+images_data[1].icon.FileName+'.'+images_data[1].icon.IconFileExtension} alt=''/>
										</div>
									)
								}

								<div className='imgBox'>
									<img src={drawing_data[0] !== undefined && process.env.PUBLIC_URL + '/DRAWING/'+drawing_data[0].icon.FileName+'.'+drawing_data[0].icon.IconFileExtension} alt=''/>
								</div>
							</div>
							<div className='article-mainWrapper-headerBox-rightBox'>
								<div className='item-content-header-features'>
									<div className='item-content-header-features-row'>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-1A') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-1A').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-1A').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-1B') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-1B').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-1B').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-1C') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-1C').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-1C').icon.IconFileExtension : featureIcon} alt=''/>
									</div>
									<div className='item-content-header-features-row'>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-3A') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-3A').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-3A').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-3B') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-3B').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-3B').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-3C') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-3C').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-3C').icon.IconFileExtension : featureIcon} alt=''/>
									</div>
									<div className='item-content-header-features-row'>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-4A') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-4A').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-4A').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-4B') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-4B').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-4B').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-4C') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-4C').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-4C').icon.IconFileExtension : featureIcon} alt=''/>
									</div>
									<div className='item-content-header-features-row'>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-S1') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-S1').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-S1').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-S2') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-S2').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-S2').icon.IconFileExtension : featureIcon} alt=''/>
										<img src={icons_data.find(item => item.position.PositionCode === 'H-TOL') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-TOL').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-TOL').icon.IconFileExtension : featureIcon} alt=''/>
									</div>
								</div>
							</div>
						</div>
						<div className='article-mainWrapper-articleBox'>
							<div className='article-mainWrapper-articleBox-leftBox'>
							<div className='table'>
								<div className='row'>
									<div className='label'>
										Technical Details
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										d1
									</div>
									<div className='value'>
										{article.d1}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										d2
									</div>
									<div className='value'>
										{article.d2}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										l1
									</div>
									<div className='value'>
										{article.L1}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										l2
									</div>
									<div className='value'>
										{article.L2}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										l3
									</div>
									<div className='value'>
										{article.L3}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										Z
									</div>
									<div className='value'>
										{article.Z}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										a
									</div>
									<div className='value'>
										{article.a}
									</div>
								</div>
								<div className='row'>
									<div className='label'>
										l
									</div>
									<div className='value'>
										{article.L}
									</div>
								</div>
							</div>
							</div>
							<div className='article-mainWrapper-articleBox-centerBox'>
							<Table
								headers={[
									{
										label: 'Suitability',
										field: 'SuitabilityID',
										type: 'suitability',
									},
									{
										label: 'Mat. Class',
										field: 'MaterialClasseID',
										type: 'render',
										props: {
											render: function(item){
												
												return (
													<img 
														style={{width: '30px', height: '30px'}} 
														src={process.env.PUBLIC_URL+'/ICON/'+item.materialClasse.icon.FileName+'.'+item.materialClasse.icon.IconFileExtension} 
														alt={item.materialClasse.icon.FileName} 
													/>
												)
												
											}
										}
									},
									{
										label: 'Hole Type',
										field: 'HoleTypeID',
										type: 'render',
										props: {
											render: function(item){
												return (
													<img 
														style={{width: '30px', height: '30px'}} 
														src={process.env.PUBLIC_URL+'/ICON/'+Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === Object.values(that.props.dataReducer.technicalCaracteristics).find(techCar => techCar.TechCarID === item.HoleTypeID).IconID).FileName
														+'.'+
														Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID).IconFileExtension} 
														alt={Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID) !== undefined && 
															Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID).FileName+'.'+Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID).IconFileExtension
														} 
													/>
												)
												
											}
										}
									},
									{
										label: 'Minimum',
										field: 'FromSpeed',
										props: {
											'suffix': 'm/min'
										}
									},
									{
										label: 'Recommended ',
										type: 'average',
										props: {
											'suffix': 'm/min',
											data:[
												'FromSpeed',
												'ToSpeed'
											],
										}
									},
									{
										label: 'Maximum',
										field: 'ToSpeed',
										props: {
											'suffix': 'm/min'
										}
									},
									
									
								]}
								data={header.workConditions}
								search={false}
							/>
							</div>
							<div className='article-mainWrapper-articleBox-rightBox'>
	
							</div>
						</div>
					</div>
		
       			</div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(Article);
