import React from 'react';
import Clock from '@fvillard/reactclock';

import '../styles/components/footer.scss';

import { connect } from 'react-redux'

import { FaSignal } from 'react-icons/fa';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		//var that = this;
		return(
			<div className='footer'>
				<div className='footer-box footer-box--first'>
					<FaSignal
						className={this.props.sessionReducer.connected ? 'footer-box--first-icon--connected' : 'footer-box--first-icon'}
						title={this.props.sessionReducer.connected ? 'Connected' : 'Not connected'}
					/>
				</div>
				<div className='footer-box'>
					<a href='https://www.nemesix.com' target='_blank' rel="noreferrer">by Nemesix</a>
				</div>
				<div className='footer-box footer-box--last'>
					<Clock />
				</div>
			</div>
		);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(Footer);
