import React from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import Interface from './components/interface';
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Provider } from 'react-redux'
import factory from './store/configureStore'
const {store, persistor} = factory();

function App() {
  const handle = useFullScreenHandle();

  return (
		<Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
  			<FullScreen handle={handle}>
  				<Interface
  					handleFullScreen={handle}
  				/>
  			</FullScreen>
      </PersistGate>
		</Provider>
  );
}
export default App;
