import React from 'react';
import Table from '../../modules/react-table/src/index.js';
import Input from '../../modules/react-input/src/index.js';
import Select from '../../modules/react-select/src/index.js';
import '../../styles/layouts/materialsAdmin/materialsAdmin.scss';
import SubNav from './subnav';


import { connect } from 'react-redux'

class MaterialsLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			onCopy: false,
			view: 'split',
			selectedItems: {},
			item: {MaterialCategoryID: 0, Description:'', Rm_N_mm2:'', Mat_Nr:'', DIN:'', AFNOR:'', BS:'', EN:'', UNI:'', UNE:'', JIS:'', SIS:'', ASI_SAE_ASTM:''},
		};
		const action_materials = { type: "get materials", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action_materials);
		const action_materials_cat = { type: "get materialsCategories", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action_materials_cat);
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onCopy = () => {
		this.state.onCopy === false && this.setState({onCopy: true})
	}
	onCancelCopy = () => {
		this.state.onCopy && this.setState({onCopy: false})
	}
	onSave = () => {
		if(this.state.item.id === ''){
			const action = { type: "create material", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		else{
			const action = { type: "save material", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (items) => {
		if(this.state.onEdit){
			if(this.state.onCopy){
				var item = this.state.item;
				item.MaterialCategoryID = Object.values(items)[0].MaterialCategoryID;
				item.Description = Object.values(items)[0].Description;
				item.Rm_N_mm2 = Object.values(items)[0].Rm_N_mm2;
				item.Mat_Nr = Object.values(items)[0].Mat_Nr;
				item.DIN = Object.values(items)[0].DIN;
				item.AFNOR = Object.values(items)[0].AFNOR;
				item.BS = Object.values(items)[0].BS;
				item.EN = Object.values(items)[0].EN;
				item.UNI = Object.values(items)[0].UNI;
				item.UNE = Object.values(items)[0].UNE;
				item.JIS = Object.values(items)[0].JIS;
				item.SIS = Object.values(items)[0].SIS;
				item.ASI_SAE_ASTM = Object.values(items)[0].ASI_SAE_ASTM;
				this.setState({item: item})
			}
			else{
				if(window.confirm('Do you really discard change ?'))
				{
					this.setState({item: Object.values(items)[0], onEdit: false})
				}
			}
		}
		else{
			this.setState({item: Object.values(items)[0]})
		}
	}
	render() {
	var that = this;
	var materialsCategories_options = [];
	if(this.props.dataReducer.materialsCategories !== undefined){
		Object.values(this.props.dataReducer.materialsCategories).forEach(function(element,index){
			materialsCategories_options.push({label: element.MaterialCategoryCode, value: element.id})
		});
	}
	return(
		<div className='materialsAdmin'>
			<SubNav
				pageState={this.state}
				onEdit={this.onEdit}
				onCopy={this.onCopy}
				onCancelCopy={this.onCancelCopy}
				onSave={this.onSave}
				onCancel={this.onCancel}
				onChangeView={this.onChangeView}
			/>
			<div className={'users-content users-content--'+this.state.view}>
				<div className={'users-content users-content--'+this.state.view+'-item'}>
				<Select
					id='MaterialCategoryID'
					placeHolder='MaterialCategory'
					error=''
					value={this.state.item.MaterialCategoryID}
					options={materialsCategories_options}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='Description'
					label='Description'
					error=''
					value={this.state.item.Description}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='Rm_N_mm2'
					label='Rm_N_mm2'
					error=''
					value={this.state.item.Rm_N_mm2}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='Mat_Nr'
					label='Mat_Nr'
					error=''
					value={this.state.item.Mat_Nr}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='DIN'
					label='DIN'
					error=''
					value={this.state.item.DIN}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='AFNOR'
					label='AFNOR'
					error=''
					value={this.state.item.AFNOR}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='BS'
					label='BS'
					error=''
					value={this.state.item.BS}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='EN'
					label='EN'
					error=''
					value={this.state.item.EN}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='UNI'
					label='UNI'
					error=''
					value={this.state.item.UNI}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='UNE'
					label='UNE'
					error=''
					value={this.state.item.UNE}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='JIS'
					label='JIS'
					error=''
					value={this.state.item.JIS}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='SIS'
					label='SIS'
					error=''
					value={this.state.item.SIS}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				<Input
					id='ASI_SAE_ASTM'
					label='ASI_SAE_ASTM'
					error=''
					value={this.state.item.ASI_SAE_ASTM}
					disabled={!this.state.onEdit}
					onChange={this.onChange}
				/>
				</div>
				<div className={'users-content users-content--'+this.state.view+'-list'}>
					<Table
						headers={
							[
								{
									label: 'MaterialCategory',
									field: 'MaterialCategoryID',
									width: '150px',
									type: 'render',
									props: {
										render: function(item){
											return that.props.dataReducer.materialsCategories[item.MaterialCategoryID] !== undefined ? that.props.dataReducer.materialsCategories[item.MaterialCategoryID].MaterialCategoryCode : '';
										}
									}
								},
								{
									label: 'Description',
									field: 'Description',
								},
								{
									label: 'Rm_N_mm2',
									field: 'Rm_N_mm2',
								},
								{
									label: 'Rockwell_HRC',
									field: 'Rockwell_HRC',
								},
								{
									label: 'Mat_Nr',
									field: 'Mat_Nr',
								},
								{
									label: 'DIN',
									field: 'DIN',
								},
								{
									label: 'AFNOR',
									field: 'AFNOR',
								},
								{
									label: 'BS',
									field: 'BS',
								},
								{
									label: 'EN',
									field: 'EN',
								},
								{
									label: 'UNI',
									field: 'UNI',
								},
								{
									label: 'UNE',
									field: 'UNE',
								},
								{
									label: 'JIS',
									field: 'JIS',
								},
								{
									label: 'SIS',
									field: 'SIS',
								},
								{
									label: 'ASI_SAE_ASTM',
									field: 'ASI_SAE_ASTM',
								},
							]
						}
						data={
							Object.values(this.props.dataReducer.materials)
						}
						onSelectionChange={function(selection){
							that.onSelectItem(selection)
						}}
					/>
				</div>
			</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(MaterialsLayout);
