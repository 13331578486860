import { combineReducers } from 'redux'
import sessionReducer from './session'
import interfaceReducer from './interface'
import dataReducer from './data'
import notificationsReducer from './notifications'
import settingsReducer from './settings'

export default combineReducers({
  sessionReducer,
  interfaceReducer,
  dataReducer,
  notificationsReducer,
  settingsReducer,
})
