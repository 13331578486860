const initialState = {
	token: '',
	connected: false,
	loged: false,
	account: {
		email: '',
		name: '',
		surname: '',
	},
}
function sessionReducer(state = initialState, action) {
	let nextState
	switch (action.type) {
		case 'SET_TOKEN':
			sessionStorage.setItem('token', action.value);
			nextState = {
				...state,
				token: action.value,
			}
			return nextState || state
		case 'CONNECT':
			nextState = {
				...state,
				connected: true,
			}
			return nextState || state
		case 'DISCONNECT':
			nextState = {
				...state,
				connected: false,
			}
			return nextState || state
		case 'SIGNUP':
			nextState = {
				...state,
				loged: true,
				account: action.value,
			}
			return nextState || state
		case 'SIGNOUT':
			nextState = {
				...state,
				loged: false
			}
			return nextState || state
		default:
			return state
	}
}
export default sessionReducer
