import React from 'react';
import './index.scss';

import Input from '../../react-input/src/index';
import Select from '../../react-select/src/index';

import { FaSortAmountDown, FaSortAmountUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

class Table extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: this.props.search === false && this.props.searchValue !== undefined ? this.props.searchValue : '',
			tempSearchValue: this.props.search === false && this.props.searchValue !== undefined ? this.props.searchValue : '',
			sorterField: this.props.sorterField !== undefined ? this.props.sorterField : this.props.headers.length > 0 ? this.props.headers[0].field : '',
			sorterDirection: 0,
			showedItems: 50,
			currentPage: 1,
		};
	}
	onSelect = (selected) => {
		var selectedItems = this.state.selectedItems;
		if(this.props.multipleSelection !== undefined && this.props.multipleSelection === true){
			if(selectedItems[selected.id] !== undefined){
				delete selectedItems[selected.id]
			}
			else{
				selectedItems[selected.id] = selected;
			}

		}
		else{
			selectedItems = {};
			selectedItems[selected.id] = selected;
		}
		this.setState({selectedItems});
		this.props.onSelectionChange !== undefined && typeof this.props.onSelectionChange === 'function' && this.props.onSelectionChange(selectedItems);
	}
	onChangeSorterField = (id) => {
		if(this.state.sorterField === id){
			this.setState({sorterDirection: this.state.sorterDirection === 0 ? 1 : 0});
		}
		else{
			this.setState({sorterField: id, sorterDirection: 0});
		}
	}
	onChangeSearch = (id, value, error) => {
		this.setState({tempSearchValue: value})
	}
	onSearchKeyDown = (event) => {
		if(event.keyCode === 13){
			this.setState({searchValue: this.state.tempSearchValue})
		}
	}
	onChangeShowedItems = (id, value, error) => {
		this.setState({showedItems: value})
	}
	onChangePage = (index) => {
		this.setState({currentPage: index})
	}
	onPreviousPage = (nbPages) => {
		var currentPage = this.state.currentPage
		if(currentPage-1 >= 1){
			currentPage = currentPage-1;
		}
		else{
			currentPage = nbPages;
		}
		this.setState({currentPage: currentPage})
	}
	onNextPage = (nbPages) => {
		var currentPage = this.state.currentPage
		if(currentPage+1 <= nbPages){
			currentPage = currentPage+1;
		}
		else{
			currentPage = 1;
		}
		this.setState({currentPage: currentPage})
	}
	renderValue = (type, props, field, item) => {
		var renderResult = (<div></div>);
		switch (type) {
			case 'pourcent':
				renderResult = (<div>{item[field]*100}%</div>);
				break;
			case 'money':
				renderResult = (<div>{parseFloat(item[field]).toFixed(2)}</div>);
				break;
			case 'bool':
				renderResult = (<div>{item[field] === 1 || item[field] === true ? 'Yes' : 'No'}</div>);
				break;
			case 'average':
				var sum = 0;
				props.data.forEach(function(field){
					sum += item[field];
				});
				
				renderResult = (<div>{Array.isArray(props.data) && props.data.length > 0 ? sum/props.data.length : 'Nan'}</div>);
				break;
			case 'img':
					const images_data = item.headersPositions.filter(headerPosition => headerPosition.icon.Type === props.Type);
					var image_data = images_data.find(item => item.position.PositionCode === props.PositionCode);
					renderResult = 	image_data !== undefined && (
						<div className="react-table-data-grid-imageBox"><img src={process.env.PUBLIC_URL + '/'+props.Type+'/'+image_data.icon.FileName+'.'+image_data.icon.IconFileExtension} alt={props.alt !== undefined ? props.alt : image_data.icon.FileName} /></div>
					);
				break;
			case 'suitability':
				var filename = '';
				switch(parseInt(item[field])){
					case 1:
						filename = 'VERY-GOOD.svg';
						break;
					case 2: 
						filename = 'GOOD.svg';
						break;
					case 3: 
						filename = 'POOR.svg';
						break;
					default:
						break;
				}
				renderResult = (
					<div className="react-table-data-grid-suitabilityBox"><img src={process.env.PUBLIC_URL + '/ICON/'+filename} alt='' /></div>
				);
				break;
			case 'render':
				renderResult = (<div>{props.render(item)}</div>);
				break;
			default:
				renderResult = (<div>{item[field]}</div>);
				break;
		}
		return renderResult;
	}
	render() {
		var that = this;
		// eslint-disable-next-line
		var evalL = eval;
		var searchFunction = function(obj){
			if(obj.Deleted !== undefined && obj.Deleted === 0){
				return true;
			}
			else if(obj.Deleted !== undefined){
				return true;
			}
			else{
				return false;
			}
		}
		var sorterFunction = function(a,b){
			if(a[that.state.sorterField] !== null && b[that.state.sorterField] !== null){
				if (a[that.state.sorterField].toString() > b[that.state.sorterField].toString()) {
					return that.state.sorterDirection === 0 ? 1 : -1;
				}
				if (a[that.state.sorterField].toString() < b[that.state.sorterField].toString()) {
					return that.state.sorterDirection === 0 ? -1 : 1;
				}
				return 0;
			}
		}
		var type = this.props.headers.find(item => item.field === this.state.sorterField) !== undefined ? this.props.headers.find(item => item.field === this.state.sorterField).type : '';
		switch(type){
			case 'int':
				sorterFunction = function(a,b){
					if(a[that.state.sorterField] !== null && b[that.state.sorterField] !== null){
						if (parseInt(a[that.state.sorterField]) > parseInt(b[that.state.sorterField])) {
							return that.state.sorterDirection === 0 ? 1 : -1;
						}
						if (parseInt(a[that.state.sorterField]) < parseInt(b[that.state.sorterField])) {
							return that.state.sorterDirection === 0 ? -1 : 1;
						}
						return 0;
					}
				}
			break;
			case 'float':
				sorterFunction = function(a,b){
					if(a[that.state.sorterField] !== null && b[that.state.sorterField] !== null){
						if (parseFloat(a[that.state.sorterField]) > parseFloat(b[that.state.sorterField])) {
							return that.state.sorterDirection === 0 ? 1 : -1;
						}
						if (parseFloat(a[that.state.sorterField]) < parseFloat(b[that.state.sorterField])) {
							return that.state.sorterDirection === 0 ? -1 : 1;
						}
						return 0;
					}
				}
			break;
			default:
			break;
		}		
		if(this.props.search === false && this.props.searchValue !== undefined && this.props.searchValue !== ''){
			searchFunction = function(obj){
				if(Object.values(obj).find(value => value !== null && value.Deleted !== 0 && value.toString().toUpperCase().match(evalL('/'+that.props.searchValue.toString().toUpperCase()+'/'))) !== undefined){
					return true;
				}
				else{
					return false;
				}
			}
		}
		else if(this.state.searchValue !== ''){
			searchFunction = function(obj){
				if(Object.values(obj).find(value => value !== null && value.Deleted !== 0 && value.toString().toUpperCase().match(evalL('/'+that.state.searchValue.toString().toUpperCase()+'/'))) !== undefined){
					return true;
				}
				else{
					return false;
				}
			}
		}
		var nbPages = this.props.data.filter(searchFunction).length % this.state.showedItems === 0 ? ((this.props.data.filter(searchFunction).length - this.props.data.filter(searchFunction).length % this.state.showedItems) / this.state.showedItems) : ((this.props.data.filter(searchFunction).length - this.props.data.filter(searchFunction).length % this.state.showedItems) / this.state.showedItems)+1;
		var previousPage = [];
		this.state.currentPage-3 > 0 && previousPage.push(this.state.currentPage-3);
		this.state.currentPage-2 > 0 && previousPage.push(this.state.currentPage-2);
		this.state.currentPage-1 > 0 && previousPage.push(this.state.currentPage-1);
		var nextPage = [];
		this.state.currentPage+1 <= nbPages && nextPage.push(this.state.currentPage+1);
		this.state.currentPage+2 <= nbPages && nextPage.push(this.state.currentPage+2);
		this.state.currentPage+3 <= nbPages && nextPage.push(this.state.currentPage+3);
        return (			
			<div className="react-table">
				<div className="react-table-header">
					<div className="react-table-header-title">
						{this.props.title !== undefined && this.props.title}
					</div>
					<div className="react-table-header-search">
						{this.props.search !== false && (
							<Input 
								id="search"
								type="search"
								value={this.state.tempSearchValue}
								onChange={this.onChangeSearch}
								onKeyDown={this.onSearchKeyDown}
							/>
						)}						
					</div>
				</div>
				<div className="react-table-data">
					<div className="react-table-data-grid" style={{gridTemplateColumns: 'repeat('+this.props.headers.length+', 1fr)',gridTemplateRows: 'repeat('+parseInt(this.props.data.filter(searchFunction).sort(sorterFunction).slice((this.state.currentPage-1)*this.state.showedItems, ((this.state.currentPage-1)*this.state.showedItems)+this.state.showedItems).length+1)+', minmax(auto, min-content)'}}>
						{this.props.headers.map((item, index) =>
							<div 
								className="react-table-data-grid-header"
								key={index}
								onClick={(that.props.sorter !== undefined && that.props.sorter === false) || item.type === 'img' || item.type === 'render' || item.type === 'average' ? function(){} : function(){that.onChangeSorterField(item.field);}}
							>
								<div className='react-table-data-grid-header-box'>
									{item.label}
									{item.field === this.state.sorterField ? this.state.sorterDirection === 0 ? (<span className='react-table-data-grid-header-box-sorteIcon'><FaSortAmountDown /></span>) : (<span className='react-table-data-grid-header-sorteIcon'><FaSortAmountUp className='react-table-element-column-header-sorteIcon' /></span>) : (<span></span>)}
								</div>
							</div>
						)}
						{this.props.data.filter(searchFunction).sort(sorterFunction).slice((this.state.currentPage-1)*this.state.showedItems, (this.state.currentPage-1)*this.state.showedItems+this.state.showedItems).map((item, item_index) =>
							this.props.headers.map((header, header_index) =>
								<div 
									key={item_index+"_"+header_index}
									className={item_index%2 === 0 ? "react-table-data-grid-item react-table-data-grid-item--even" : "react-table-data-grid-item"}
									onClick={function(){that.onSelect(item)}}
								>
									{header.type !== undefined ?  this.renderValue(header.type, header.props, header.field, item) : (<div>{item[header.field]}</div>)}
									{header.props !== undefined && header.props.suffix !== undefined && header.props.suffix !== '' && (<span style={{marginLeft: '5px'}}>{' '+header.props.suffix}</span>)}
								</div>
							)
						)}
					</div>
				</div>
				<div className="react-table-footer">
					<div className="react-table-footer-left">
						<div className="react-table-footer-infos">
							{this.props.data.filter(searchFunction).length > 0 ? ((this.state.currentPage-1)*this.state.showedItems)+1 : 0} - {((this.state.currentPage-1)*this.state.showedItems)+this.state.showedItems > this.props.data.filter(searchFunction).length ? this.props.data.filter(searchFunction).length : ((this.state.currentPage-1)*this.state.showedItems)+this.state.showedItems} / {this.props.data.filter(searchFunction).length} items
						</div>
					</div>
					<div className="react-table-footer-center">
						<div className="react-table-pagination">
							<FaChevronLeft className="react-table-pagination-action" onClick={function(){that.onPreviousPage(nbPages)}} />
							<span className="react-table-pagination-previous">
								{previousPage.map((item, index) =>
									<div key={index} onClick={function(){that.onChangePage(item)}}>{item}</div>	
								)}
							</span>
							<span className="react-table-pagination-current">
								{this.state.currentPage}
							</span>
							<span className="react-table-pagination-next">
								{nextPage.map((item, index) =>
									<div key={index} onClick={function(){that.onChangePage(item)}}>{item}</div>	
								)}
							</span>
							<FaChevronRight className="react-table-pagination-action" onClick={function(){that.onNextPage(nbPages)}} />
						</div>
					</div>
					<div className="react-table-footer-right">
						<div className="react-table-footer-itemShowed">
							<Select
								id="showedItems"
								options={
									[
										{label: '20', value: 20},
										{label: '50', value: 50},
										{label: '100', value: 100},
										{label: '500', value: 500}
									]
								}
								value={this.state.showedItems}
								show="up"
								onChange={this.onChangeShowedItems}
							/>
						</div>
					</div>
				</div>
			</div>
		)
    }
}
export default Table;