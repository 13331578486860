import React from 'react';
import Table from '../../modules/react-table/src/index.js';
import Input from '../../modules/react-input/src/index.js';
import ReactRepeater from '../../modules/react-repeater/dist/index.js';
import '../../styles/layouts/headersAdmin/headersAdmin.scss';
import SubNav from './subnav';

import { connect } from 'react-redux'

class NewHeaderLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			onCopy: false,
			view: 'split',
			selectedItems: {},
			item: {
				HeaderNo:'', 
				PageType:'', 
				CategoryCode:'', 
				Special1:'', 
				Special2:'', 
				T1:'', 
				T2:'', 
				SubCat1:'', 
				SubCat1_Icon:'', 
				SubCat2:'', 
				WorkMaterial_Text1:'', 
				WorkMaterial_Text2:'',

				headersPositions: []
			},
		
			elements: []
		};
		const action_headers = { type: "get headers", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action_headers);
		const action_positions = { type: "get positions", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action_positions);
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onSave = () => {
		console.log(this.state.item)
		const action = { type: "save header", value: this.state.item, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action);
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (items) => {
		if(this.state.onEdit){
			if(this.state.onCopy){
				var item = this.state.item;
				item.HeaderNo = Object.values(items)[0].HeaderNo;
				item.PageType = Object.values(items)[0].PageType;
				item.CategoryCode = Object.values(items)[0].CategoryCode;
				item.T1 = Object.values(items)[0].T1;
				item.T2 = Object.values(items)[0].T2;
				item.SubCat1 = Object.values(items)[0].SubCat1;
				item.SubCat1_Icon = Object.values(items)[0].SubCat1_Icon;
				item.SubCat2 = Object.values(items)[0].SubCat2;
				item.SubCat2_Icon = Object.values(items)[0].SubCat2_Icon;
				item.WorkMaterial_Text1 = Object.values(items)[0].WorkMaterial_Text1;
				item.WorkMaterial_Text2 = Object.values(items)[0].WorkMaterial_Text2;


				item.headersPositions = Object.values(items)[0].headersPositions;
				this.setState({item: item})
			}
			else{
				if(window.confirm('Do you really discard change ?'))
				{
					this.setState({item: Object.values(items)[0], onEdit: false})
				}
			}
		}
		else{
			this.setState({item: Object.values(items)[0]})
		}
	}
	render() {
			var that = this;
			var icons_options = [];
			if(this.props.dataReducer.icons !== undefined){
				Object.values(this.props.dataReducer.icons).forEach(function(element,index){
					icons_options.push({label: element.FileName, value: element.id})
				});
			}
			var positions_options = [];
			if(this.props.dataReducer.positions !== undefined){
				Object.values(this.props.dataReducer.positions).forEach(function(element,index){
					positions_options.push({label: element.PositionCode, value: element.id})
				});
			}

			var filterFunction = function(obj){
				if(obj.CategoryCode === null){
					return true;
				}	
				else{
					return false;
				}
			}
			return(
				<div className='headersAdmin'>
					
					<SubNav
						pageState={this.state}
						onEdit={this.onEdit}
						// onCopy={this.onCopy}
						// onCancelCopy={this.onCancelCopy}
						onSave={this.onSave}
						onCancel={this.onCancel}
						onChangeView={this.onChangeView}
					/>
					<div className={'users-content users-content--'+this.state.view}>
						<div className={'users-content users-content--'+this.state.view+'-item'}>
							<ReactRepeater 
								id='headersPositions'
								ids={['PositionID','IconID']}
								types={['select','select']}
								props={[
									{
										required:true,
										options: positions_options,
									},
									{
										required:true,
										options: icons_options,
									}
								]}
								placeHolders={['Position','Icon']}
								items={Object.values(this.state.item.headersPositions).filter(function(item){return item.Deleted !== 1})}
								onChange={this.onChange}
								disabled={!this.state.onEdit}
							/>
							<Input
								id='HeaderNo'
								label='HeaderNo'
								error=''
								value={this.state.item.HeaderNo}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='PageType'
								label='PageType'
								error=''
								value={this.state.item.PageType}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>						
							<Input
								id='T1'
								label='T1'
								error=''
								value={this.state.item.T1}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='T2'
								label='T2'
								error=''
								value={this.state.item.T2}
								disabled={!this.state.T2}
								onChange={this.onChange}
							/>
							<Input
								id='SubCat1'
								label='SubCat1'
								error=''
								value={this.state.item.SubCat1}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='SubCat1_Icon'
								label='SubCat1_Icon'
								error=''
								value={this.state.item.SubCat1_Icon}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='SubCat2'
								label='SubCat2'
								error=''
								value={this.state.item.SubCat2}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>			
						</div>
						<div className={'users-content users-content--'+this.state.view+'-list'}>
							<Table
								headers={
									[
										{
											label: 'HeaderNo',
											field: 'HeaderNo',
											width: '100px',
										},
										{
											label: 'PageType',
											field: 'PageType',
											width: '80px',
										},
										{
											label: 'CategoryCode',
											field: 'CategoryCode',
											width: '80px',
										},
										{
											label: 'Special1',
											field: 'Special1',
											width: '80px',
										},
										{
											label: 'Special2',
											field: 'Special2',
											width: '80px',
										},
										{
											label: 'T1',
											field: 'T1',
											width: '80px',
										},
										{
											label: 'T2',
											field: 'T2',
											width: '100px',
										},
										{
											label: 'SubCat1',
											field: 'SubCat1',
											width: '100px',
										},
										{
											label: 'SubCat1_Icon',
											field: 'SubCat1_Icon',
											width: '100px',
										},
										{
											label: 'SubCat2',
											field: 'SubCat2',
											width: '100px',
										},
										{
											label: 'WorkMaterial_Text1',
											field: 'WorkMaterial_Text1',
											width: '150px',
										},
										{
											label: 'WorkMaterial_Text2',
											field: 'WorkMaterial_Text2',
											width: '150px',
										},
									]
								}
								data={
									Object.values(this.props.dataReducer.headers).filter(filterFunction)
								}
								onSelectionChange={function(selection){
									that.onSelectItem(selection)
								}}
							/>
						</div>
					</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(NewHeaderLayout);