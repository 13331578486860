const initialState = {
	currentPage: window.location.pathname.split('/')[1],
	currentPageArgs: null,
}
function interfaceReducer(state = initialState, action) {
	let nextState
	switch (action.type) {
		case 'CHANGE_CURRENT_PAGE':
			window.history.pushState({}, '', '/'+action.value+'/');
			if(action.args !== undefined){
				nextState = {
					...state,
					currentPage: action.value,
					currentPageArgs: action.args,
				}
			}
			else{
				nextState = {
					...state,
					currentPage: action.value,
					currentPageArgs: null,
				}
			}
			return nextState || state
			// eslint-disable-next-line
		break;
		case 'CLEAR_CURRENT_PAGE_ARGS':
			console.log('clear args')
			nextState = {
				...state,
				currentPageArgs: null,
			}
			return nextState || state
			// eslint-disable-next-line
		break;
		case 'DOWNLOAD_CSV_FILE':
			var element = document.createElement('a');
			element.setAttribute('href','data:text/plain;charset=utf-8, ' + encodeURIComponent(action.value.data));
			element.setAttribute('download', action.value.name);
			document.body.appendChild(element);
			element.click();
			return nextState || state
			// eslint-disable-next-line
		break;
		default:
			return state
	}
}
export default interfaceReducer
