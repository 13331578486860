import React from 'react';
import Select from '../../modules/react-select/src/index.js';
import Table from '../../modules/react-table/src/index.js';
import Input from '../../modules/react-input/src/index.js';
import '../../styles/layouts/materialsClassesAdmin/materialsClassesAdmin.scss';
import SubNav from './subnav';


import { connect } from 'react-redux'

class MaterialsClassesLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			onCopy: false,
			view: 'split',
			selectedItems: {},
			item: {MaterialClassID:'', IconID:'', Description:'', ShortDescription:'', SortingNo:0},

		};
		const action_icons = { type: "get icons", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action_icons);
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onCopy = () => {
		this.state.onCopy === false && this.setState({onCopy: true})
	}
	onCancelCopy = () => {
		this.state.onCopy && this.setState({onCopy: false})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onSave = () => {
		if(this.state.item.id === ''){
			const action = { type: "create materialClasse", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		else{
			const action = { type: "save materialClasse", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (items) => {
		if(this.state.onEdit){
			if(this.state.onCopy){
				var item = this.state.item;
				item.IconID = Object.values(items)[0].IconID;
				item.Description = Object.values(items)[0].Description;
				item.ShortDescription = Object.values(items)[0].ShortDescription;
				item.SortingNo = Object.values(items)[0].SortingNo;
				this.setState({item: item})
			}
			else{
				if(window.confirm('Do you really discard change ?'))
				{
					this.setState({item: Object.values(items)[0], onEdit: false})
				}
			}
		}
		else{
			this.setState({item: Object.values(items)[0]})
		}	
	}
	render() {
			var that = this;
			var icons_options = [];
			if(this.props.dataReducer.icons !== undefined){
				Object.values(this.props.dataReducer.icons).forEach(function(element,index){
					icons_options.push({label: element.FileName, value: element.id})
				});
			}
			return(
				<div className='materialsAdmin'>
					<SubNav
						pageState={this.state}
						onEdit={this.onEdit}
						onCopy={this.onCopy}
						onCancelCopy={this.onCancelCopy}
						onSave={this.onSave}
						onCancel={this.onCancel}
						onChangeView={this.onChangeView}
					/>
					<div className={'users-content users-content--'+this.state.view}>
						<div className={'users-content users-content--'+this.state.view+'-item'}>
						<Select
							id='IconID'
							placeHolder='Icon'
							error=''
							value={this.state.item.IconID}
							options={icons_options}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='Description'
							label='Description'
							error=''
							value={this.state.item.Description}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						
						<Input
							id='ShortDescription'
							label='ShortDescription'
							error=''
							value={this.state.item.ShortDescription}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='SortingNo'
							label='Sorting'
							type='number'
							error=''
							value={this.state.item.SortingNo}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						
						</div>
						<div className={'users-content users-content--'+this.state.view+'-list'}>
							<Table
								headers={
									[
										{
											label: 'Icon',
											field: 'IconID',
											width: '150px',
											type: 'render',
											props: {
												render: function(item){
													return that.props.dataReducer.icons[item.IconID].FileName;
												}
											}
										},
										{
											label: 'Description',
											field: 'Description',
											width: '200px',
										},
										
										{
											label: 'ShortDescription',
											field: 'ShortDescription',
											width: '150px',
										},
										{
											label: 'Sorting',
											field: 'SortingNo',
											width: '100px',
										}
									]
								}
								data={
									Object.values(this.props.dataReducer.materialsClasses)
								}
								onSelectionChange={function(selection){
									that.onSelectItem(selection)
								}}
							/>
						</div>
					</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(MaterialsClassesLayout);
