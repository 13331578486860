const initialState = {
	notifications: [],
	showAll: false,
}
const { uuid } = require('uuidv4');
function notificationsReducer(state = initialState, action) {
	let nextState
	var notifications = null;
	switch (action.type){
		case 'MESSAGE': 
			action.value.uid = uuid();
			action.value.showed = true;
			action.value.closed = false;
			nextState = {
				...state,
				notifications: [...state.notifications, action.value],
			}
		break;
		case 'HIDE_MESSAGE':
			notifications = state.notifications;
			notifications.find(item => item.uid === action.value).showed = false;
			nextState = {
				...state,
				notifications: notifications,
			}
		break;
		case 'CLOSE_MESSAGE':
			notifications = state.notifications;
			notifications.find(item => item.uid === action.value).closed = false;
			nextState = {
				...state,
				notifications: notifications,
			}
		break;
		case 'CONSOLE':
			console.info(action);
		break;
		default:
			return state
	}
	return nextState || state
}
export default notificationsReducer
