import React from 'react';
import '../styles/components/interface.scss';

import MainNavigation from './mainNavigation';
//import ReactNotifications from '../modules/react-notifications/dist/index.js';
import ReactNotifications from '@fvillard/react-notifications';
import Content from './content';
import Footer from './footer';

import { connect } from 'react-redux';

class Interface extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		return(
			<div className='interface'>
	      <MainNavigation
					handleFullScreen={this.props.handleFullScreen}
				/>
				<Content

				/>
				<Footer

				/>
				<ReactNotifications 
					reducer={this.props.notificationsReducer}
					dispacher={this.props.dispatch}
				/>
			</div>
		);
	}
}
const StateToProps = (state) => {
	return state
  }
  const DispatchToProps = (dispatch) => {
	return {
	  dispatch: (action) => { dispatch(action) }
	}
  }
  export default connect(StateToProps,DispatchToProps)(Interface);