const initialState = {

}
function settingsReducer(state = initialState, action) {
	//let nextState
	switch (action.type) {
		default:
			return state
	}
}
export default settingsReducer
