import React from 'react';
import Table from '../../modules/react-table/src/index.js';
import '../../styles/layouts/users/users.scss';
import SubNav from './subnav';
import Input from '../../modules/react-input/src/index.js';
import Select from '../../modules/react-select/src/index.js';

import { connect } from 'react-redux'

class UsersLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			view: 'split',
			selectedItems: {},
			item: {id:'', Email:'', Name:'', Surname: '', Role:'',ShowedStock:''},
		};
		const action = { type: "get accounts", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action);
	}
	onNew = () => {
		this.setState({onEdit: true, item: {id:'', Email:'', Name:'', Surname: '', Password: '', Role:'',ShowedStock:''}})
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onSave = () => {
		if(this.state.item.id === ''){
			const action = { type: "create account", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		else{
			const action = { type: "save account", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (data) => {
		var item = Object.values(data)[0];
		item.Password = '';
		if(this.state.onEdit){
			if(window.confirm('Do you really discard change ?'))
			{	
				this.setState({item: item, onEdit: false})
			}
		}
		else{
			this.setState({item: item})
		}
		
	}
	render() {
		var that = this;
		return(
			<div className='users'>
				<SubNav
					pageState={this.state}
					onNew={this.onNew}
					onEdit={this.onEdit}
					onSave={this.onSave}
					onCancel={this.onCancel}
					onChangeView={this.onChangeView}
				/>
				<div className={'users-content users-content--'+this.state.view}>
					<div className={'users-content users-content--'+this.state.view+'-item'}>
						<Input
							id='Email'
							label='Email'
							type='email'
							error=''
							value={this.state.item.Email}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='Password'
							label='Password'
							type='password'
							value={this.state.item.Password}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='Name'
							label='Name'
							value={this.state.item.Name}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='Surname'
							label='Surname'
							value={this.state.item.Surname}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>

						<Input
							id='ShowedStock'
							type='pourcent'
							label='Showed Stock (%)'
							value={this.state.item.ShowedStock}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Select
							id='Role'
							placeHolder='Role'
							value={this.state.item.Role}
							options={[
								{label: 'Anonimous', value: 0},
								{label: 'Guest', value: 1},
								{label: 'User', value: 2},
								{label: 'Admin', value: 3},
							]}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
					</div>
					<div className={'users-content users-content--'+this.state.view+'-list'}>
						<Table
							headers={
								[
									{
										label: '#',
										field: 'id',
										type: 'int'
									},
									{
										label: 'Email',
										field: 'Email',
									},
									{
										label: 'Name',
										field: 'Name',
									},
									{
										label: 'Surname',
										field: 'Surname',
									},
									{
										label: 'Role',
										field: 'Role',
									},
									{
										label: 'Showed Stock',
										field: 'ShowedStock',
										type: 'pourcent'
									},
								]
							}
							data={
								Object.values(this.props.dataReducer.accounts)
							}
							onSelectionChange={function(selection){
								that.onSelectItem(selection)
							}}
						/>
					</div>
				</div>
        	</div>
		);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(UsersLayout);
