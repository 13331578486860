import React from 'react';
import Button from '@fvillard/reactbutton';
import Select from '../../modules/react-select/src/index.js';
import '../../styles/layouts/home.scss';

import toolSelectorRedIcon from '../../assets/img/mainNavigation/toolSelector-red.png';
import searchRedIcon from '../../assets/img/mainNavigation/search-red.png';

import { connect } from 'react-redux'

class HomeLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		const action = { type: "get headers", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action);
		const articles_action = { type: "get articles", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(articles_action);
	}
	onSelectHeader = (id,value,error) => {
		const item = Object.values(this.props.dataReducer.headers).find(item => item.HeaderNo === value);
		const action = { type: "CHANGE_CURRENT_PAGE", value: 'tool-selector', args: {selectedItem: item}, socket: {
			send: false
		}}
		this.props.dispatch(action)
	}
	onSelectArticle = (id,value,error) => {
		const item = Object.values(this.props.dataReducer.articles).find(item => item.id === parseInt(value));
		const action = { type: "CHANGE_CURRENT_PAGE", value: 'article', args: {item: item}, socket: {
			send: false
		}}
		this.props.dispatch(action)
	}
	render() {
		var that = this;
		var headers_options = [];
		Object.values(this.props.dataReducer.headers).forEach(function(element,index){
			headers_options.push({label: element.HeaderNo, value: element.HeaderNo})
		});
		var articles_options = [];
		Object.values(this.props.dataReducer.articles).forEach(function(element,index){
			articles_options.push({label: element.ArticleNo, value: element.id})
		});
		
		return(
		<div className='home'>
          <div className='home-actions'>
				<div className='home-actions-item'>
					<div className='home-actions-item-icon'>
						<img src={toolSelectorRedIcon} alt='' />
					</div>
					<div className='home-actions-item-title'>
						Tool selector
					</div>
					<div className='home-actions-item-description'>
						Search by application
					</div>
					<div className='home-actions-item-actions'>
						<Button
							className='home-actions-item-actions-button'
							label='Select'
							onClick={function(){
								const action = { type: "CHANGE_CURRENT_PAGE", value: 'tool-selector', socket: {
									send: false
								}}
								that.props.dispatch(action)
							}}
						/>
					</div>
				</div>
				<div className='home-actions-item'>
					<div className='home-actions-item-icon'>
						<img src={searchRedIcon} alt='' />
					</div>
					<div className='home-actions-item-title'>
						Direct Search
					</div>
					<div className='home-actions-item-description'>
						Search by Code or Catalog Number
					</div>
					<div className='home-actions-item-actions'>
						<Select
							className=''
							id='MaterialCategoryID'
							placeHolder='Select your header'
							error=''
							value={0}
							options={headers_options}
							onChange={this.onSelectHeader}
							required
						/>
						<Select
							className=''
							id='MaterialCategoryID'
							placeHolder='Select your article'
							error=''
							value={0}
							options={articles_options}
							onChange={this.onSelectArticle}
							required
						/>
					</div>
				</div>
			</div>
        </div>
		);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(HomeLayout);
