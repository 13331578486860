import React from 'react';
import Button from '@fvillard/reactbutton';
import Input from '../../modules/react-input/src/index.js';
import Select from '../../modules/react-select/src/index.js';
import Table from '../../modules/react-table/src/index';
import '../../styles/layouts/toolSelector.scss';

import leftChevronRedIcon from '../../assets/img/leftChevron-red.png';
import toolSelectorRedIcon from '../../assets/img/mainNavigation/toolSelector-red.png';
import featureIcon from '../../assets/img/feature.svg';

import { connect } from 'react-redux';

class ToolSelectorLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showMaterialSelector: false,
			selectedMaterial: null,
			selectedItem: this.props.interfaceReducer.currentPageArgs !== undefined && this.props.interfaceReducer.currentPageArgs !== null && this.props.interfaceReducer.currentPageArgs.selectedItem !== undefined ? this.props.interfaceReducer.currentPageArgs.selectedItem : null,
			searchText: '',
			tempSearchText: '',
			searchIso: '',
			searchTanoi: '',


			searchCategoryCode: '',
			searchThreadType: '',
			searchThread: '',
			searchTol: '',
			searchHoleType: '',
			searchMaterialClass: '',
			
		};

		const headers_action = { type: "get headers", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(headers_action);
		const articles_action = { type: "get articles", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(articles_action);
		const materials_action = { type: "get materials", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(materials_action);
		
		const materials_types_action = { type: "get materialsTypes", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(materials_types_action);

		const icons_action = { type: "get icons", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(icons_action);
		
		const materials_classes_action = { type: "get materialsClasses", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(materials_classes_action);


		const technicalCaracteristics_action = { type: "get technicalCaracteristics", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(technicalCaracteristics_action);
	}
	onToggleShowMaterialSelection = () => {
		this.setState({showMaterialSelector: this.state.showMaterialSelector ? false : true})
	}
	onSelectItem = (selection) => {
		this.setState({selectedItem: Object.values(selection)[0]})
	}
	onSelectArticle = (selection) => {
		const action = { type: "CHANGE_CURRENT_PAGE", value: 'article', args: {item: Object.values(selection)[0]}, socket: {
			send: false
		}}
		this.props.dispatch(action)
	}
	onSelectMaterial = (selection) => {
		this.setState({selectedMaterial: Object.values(selection)[0], showMaterialSelector: false, searchMaterialClass: Object.values(selection)[0].materialCategorie.materialClasse.id})
	}
	onCloseItem = (selection) => {
		var that = this;
		this.setState({selectedItem: null})
		const action = { type: "CLEAR_CURRENT_PAGE_ARGS", socket: {
			send: false
		}}
		that.props.dispatch(action)
	}
	onChangeSearchText = (id,value,error) => {
		this.setState({tempSearchText: value})
	}
	onSearchTextKeyDown = (event) => {
		if(event.keyCode === 13){
			this.setState({searchText: this.state.tempSearchText})
		}
	}
	onChangeIsoSearch = (id,value,error) => {
		this.setState({searchIso: value})
	}
	onChangeTanoiSearch = (id,value,error) => {
		this.setState({searchTanoi: value})
	}
	//TOOL SELECTOR SELECTS EVENT
	onChangeCategoryCodeSearch = (id,value,error) => {
		this.setState({searchCategoryCode: value})
	}
	onChangeThreadTypeSearch = (id,value,error) => {
		this.setState({searchThreadType: value})
	}
	onChangeThreadSearch = (id,value,error) => {
		this.setState({searchThread: value})
	}
	onChangeTolSearch = (id,value,error) => {
		this.setState({searchTol: value})
	}
	onChangeHoleTypeSearch = (id,value,error) => {
		this.setState({searchHoleType: value})
	}
	onChangeMaterialClassSearch = (id,value,error) => {
		this.setState({searchMaterialClass: value})
	}
	onClearFilters = () => {
		this.setState({
			searchCategoryCode: '',
			searchThreadType: '',
			searchThread: '',
			searchTol: '',
			searchHoleType: '',
			searchMaterialClass: '',
		})
	}
	//----------------------------------------------------------
	renderMaterials = () => {
		var that = this;
		// eslint-disable-next-line
		var evalL = eval;
		var materials_options = [];
		if(this.props.dataReducer.materials !== undefined){
			Object.values(this.props.dataReducer.materials).forEach(function(element,index){
				materials_options.push({label: element.Description, value: element.id})
			});
		}
		var materials_types_options = [];
		if(this.props.dataReducer.materialsTypes !== undefined){
			Object.values(this.props.dataReducer.materialsTypes).forEach(function(element,index){

				materials_types_options.push({label: element.MaterialTypeCode+' - '+element.Description, value: element.id})
			});
		}
		var materials_classes_options = [];
		if(this.props.dataReducer.materialsClasses !== undefined){
			Object.values(this.props.dataReducer.materialsClasses).forEach(function(element,index){
				if(element.id !== 1){
					materials_classes_options.push({label: element.ShortDescription, value: element.id})
				}
			});
		}



		var filterFunction = function(obj){
			if(obj.Deleted !== 1){
				return true;
			}
			else{
				return false;
			}
		}

		if(this.state.searchIso !== '' && this.state.searchTanoi !== ''){
			filterFunction = function(obj){
				if(obj.materialCategorie.materialType.id === parseInt(that.state.searchIso) && obj.materialCategorie.materialClasse.id === parseInt(that.state.searchTanoi)){
					return true;
				}
				else{
					return false;
				}
			}
		}
		else if(this.state.searchIso !== ''){
			filterFunction = function(obj){
				if(obj.materialCategorie.materialType.id === parseInt(that.state.searchIso)){
					return true;
				}
				else{
					return false;
				}
			}
		}
		else if(this.state.searchTanoi !== ''){
			filterFunction = function(obj){
				if(obj.materialCategorie.materialClasse.id === parseInt(that.state.searchTanoi)){
					return true;
				}
				else{
					return false;
				}
			}
		}
		return(
			<div className='materialsSelector'>
				<div className='materialsSelector-subnav'>
					<div className='materialsSelector-subnav-left-box'>
						<div className='materialsSelector-subnav-return-box' onClick={this.onToggleShowMaterialSelection}>
							<img className='materialsSelector-subnav-icon' src={leftChevronRedIcon} alt='' />
							Back
						</div>
					</div>
					<div className='materialsSelector-subnav-center-box'>

					</div>
					<div className='materialsSelector-subnav-right-box'>
					</div>
				</div>
				<div className='materialsSelector-content'>
					<div className='materialsSelector-content-title'>
						<div className='materialsSelector-content-title-main'>
							Select your material
						</div>

					</div>
					<div className='materialsSelector-content-filters'>
						<div className='materialsSelector-content-filters-item'>
							<div className='materialsSelector-content-filters-item-title'>
								Search Material by Text
							</div>
							<div className='materialsSelector-content-filters-item-input'>
								<Input
									id='search_materials'
									placeHolder='Search your material'
									value={this.state.tempSearchText}
									onChange={this.onChangeSearchText}
									onKeyDown={this.onSearchTextKeyDown}
								/>
							</div>
						</div>
						<div className='materialsSelector-content-filters-item'>
							<div className='materialsSelector-content-filters-item-title'>
								ISO Material Classification
							</div>
							<div className='materialsSelector-content-filters-item-input'>
								<Select
									id='iso_materials'
									placeHolder='Search your material'
									options={materials_types_options}
									onChange={this.onChangeIsoSearch}
								/>
							</div>
						</div>
						<div className='materialsSelector-content-filters-item'>
							<div className='materialsSelector-content-filters-item-title'>
								TANOI Material Classification
							</div>
							<div className='materialsSelector-content-filters-item-input'>
								<Select
									id='tanoi_materials'
									placeHolder='Search your material'
									options={materials_classes_options}
									onChange={this.onChangeTanoiSearch}
								/>
							</div>
						</div>
					</div>
					<div className='materialsSelector-content-list'>
						<Table
							height={'300px'}
							headers={
								[
									{
										label: 'Mat_Nr',
										field: 'Mat_Nr',
										width: '80px',
										type: 'render',
										props:{
											render: function(item){
												return (<span>{parseFloat(item['Mat_Nr']).toFixed(4)}</span>)
											}
										}
										
									},
									{
										label: 'Din',
										field: 'DIN',
										width: '150px',
									},
									{
										label: 'AFNOR',
										field: 'AFNOR',
										width: '150px',
									},
									{
										label: 'ASI_SAE_ASTM',
										field: 'ASI_SAE_ASTM',
										width: '100px',
									},
									{
										label: 'BS',
										field: 'BS',
										width: '120px',
									},
									{
										label: 'EN',
										field: 'EN',
										width: '100px',
									},
									{
										label: 'JIS',
										field: 'JIS',
										width: '50px',
									},
									{
										label: 'Rm_N_mm2',
										field: 'Rm_N_mm2',
										width: '100px',
									},
									{
										label: 'Rockwell_HRC',
										field: 'Rockwell_HRC',
										width: '300px',
									},
								]
							}
							data={Object.values(this.props.dataReducer.materials).filter(filterFunction)}
							search={false}
							searchValue={this.state.searchText}
							onSelectionChange={function(selection){
								that.onSelectMaterial(selection)
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderSelector = () => {
		var that = this;
		var materials_options = [];
		if(this.props.dataReducer.materials !== undefined){
			Object.values(this.props.dataReducer.materials).forEach(function(element,index){
				materials_options.push({label: element.Description, value: element.id})
			});
		}
		var materials_types_options = [];
		if(this.props.dataReducer.materialsTypes !== undefined){
			Object.values(this.props.dataReducer.materialsTypes).forEach(function(element,index){
				materials_types_options.push({label: element.MaterialTypeCode+' - '+element.Description, value: element.id})
			});
		}
		var materials_classes_options = [];
		if(this.props.dataReducer.materialsClasses !== undefined){
			Object.values(this.props.dataReducer.materialsClasses).forEach(function(element,index){
				if(element.id !== 1){
					materials_classes_options.push({label: element.ShortDescription, value: element.id})
				}
			});
		}
		var category_code_options = [];
		if(this.props.dataReducer.headers !== undefined){
			Object.values(this.props.dataReducer.headers).forEach(function(element,index){
				if(element.CategoryCode !== null && category_code_options.find(option => option.label === element.CategoryCode) === undefined){
					category_code_options.push({label: element.CategoryCode, value: element.CategoryCode})
				}
			});
		}
		var thread_types_options = [];
		if(this.props.dataReducer.articles !== undefined){
			Object.values(this.props.dataReducer.articles).forEach(function(element,index){
				if(element.ThreadType !== null && thread_types_options.find(option => option.label === element.ThreadType) === undefined){
					thread_types_options.push({label: element.ThreadType, value: element.ThreadType})
				}
			});
		}
		var thread_options = [];
		//WARNING DOUBLONS
		if(this.props.dataReducer.articles !== undefined){
			Object.values(this.props.dataReducer.articles).forEach(function(element,index){
				if(element.d1 !== null && (element.d1[0] === 'M' || element.d1[0] === 'S')){
					thread_options.push({label: element.d1+'X'+element.P, value: element.d1+'X'+element.P})
				}
				else{
					thread_options.push({label: element.d1+'-'+element.P, value: element.d1+'-'+element.P})
				}
			});
		}
		var tol_options = [];
		if(this.props.dataReducer.technicalCaracteristics !== undefined){
			Object.values(this.props.dataReducer.technicalCaracteristics).forEach(function(element,index){
				if(element.Type === 'TOLERANCES'){
					tol_options.push({label: element.ShortDescription, value: element.IconID})
				}
			})
		}
		var hole_options = [];
		if(this.props.dataReducer.technicalCaracteristics !== undefined){
			Object.values(this.props.dataReducer.technicalCaracteristics).forEach(function(element,index){
				if(element.Type === 'HOLE TYPES'){
					hole_options.push({label: element.ShortDescription, value: element.IconID})
				}
			});
		}
		var filterFunction = function(obj){
			if(obj.Deleted === 1){
				return false;
			}
			if(that.state.searchCategoryCode !== ''){
				if(obj.CategoryCode !== that.state.searchCategoryCode){
					return false;
				}
			}
			if(that.state.searchThreadType !== ''){
				if(obj.articles.find(item => item.ThreadType === that.state.searchThreadType) === undefined){
					return false;
				}
			}
			
			if(that.state.searchThread !== ''){
				var value = that.state.searchThread.split('').includes('X') ? that.state.searchThread.split('X') : that.state.searchThread.split('-');
				var d1 = value[0];
				var P = value[1];
				if(obj.articles.find(item => item.d1 === parseFloat(d1) && item.P === parseFloat(P)) === undefined){
					return false;
				}
			}
			if(that.state.searchTol !== ''){
				if(obj.headersPositions.find(item => item.position.PositionCode === 'H-TOL' && item.icon.id === parseInt(that.state.searchTol)) === undefined){
					return false;
				}
			}
			if(that.state.searchHoleType !== ''){
				if(obj.headersPositions.find(item => item.position.PositionCode === 'H-S1' && item.icon.id === parseInt(that.state.searchHoleType)) === undefined){
					return false;
				}
			}
			// if(that.state.searchMaterialClass !== ''){
			// 	if(obj.headersPositions.find(item => item.position.PositionCode === 'H-S1' && item.icon.id === that.state.searchHoleType) === undefined){
			// 		return false;
			// 	}
			// }
		
			return true;
		}
		return(
			<div className='toolsSelector'>
				<div className='toolsSelector-panel'>
					<div className='toolsSelector-panel-title'>
						<img className='toolsSelector-panel-title-icon' src={toolSelectorRedIcon} alt='' />
						<div className='toolsSelector-panel-title-text'>
							Tool Selector
						</div>
					</div>
					<div className='toolsSelector-panel-filters'>
						<div className='toolsSelector-panel-filters-item'>
							<div className='toolsSelector-panel-filters-item-title'>
								Category Code
							</div>
							<Select
								id='category_code'
								className='toolsSelector-panel-filters-item-input'
								options={category_code_options}
								placeHolder="All items"
								value={this.state.searchCategoryCode}
								onChange={this.onChangeCategoryCodeSearch}
							/>
						</div>
						<div className='toolsSelector-panel-filters-item'>
							<div className='toolsSelector-panel-filters-item-title'>
								Thread Type
							</div>
							<Select
								id='thread_type'
								className='toolsSelector-panel-filters-item-input'
								options={thread_types_options}
								placeHolder="All items"
								value={this.state.searchThreadType}
								onChange={this.onChangeThreadTypeSearch}
							/>
						</div>
						<div className='toolsSelector-panel-filters-item'>
							<div className='toolsSelector-panel-filters-item-title'>
								Size
							</div>
							<Select
								id='thread'
								className='toolsSelector-panel-filters-item-input'
								options={thread_options}
								placeHolder="All items"
								value={this.state.searchThread}
								onChange={this.onChangeThreadSearch}
							/>
						</div>
						<div className='toolsSelector-panel-filters-item'>
							<div className='toolsSelector-panel-filters-item-title'>
								Tol
							</div>
							<Select
								id='tol'
								className='toolsSelector-panel-filters-item-input'
								options={tol_options}
								placeHolder="All items"
								value={this.state.searchTol}
								onChange={this.onChangeTolSearch}
							/>
						</div>
						<div className='toolsSelector-panel-filters-item'>
							<div className='toolsSelector-panel-filters-item-title'>
								Hole Type
							</div>
							<Select
								id='hole_type'
								className='toolsSelector-panel-filters-item-input'
								options={hole_options}
								placeHolder="All items"
								value={this.state.searchHoleType}
								onChange={this.onChangeHoleTypeSearch}
							/>
						</div>
						<div className='toolsSelector-panel-filters-item'>
							<div className='toolsSelector-panel-filters-item-title'>
								Material Class
							</div> 
							<Select
								id='material_class'
								className='toolsSelector-panel-filters-item-input'
								options={materials_classes_options}
								placeHolder="All items"
								value={this.state.searchMaterialClass}
								onChange={this.onChangeMaterialClassSearch}
								disabled={true}
							/>
						</div>
					</div>
					<div className='toolsSelector-panel-actions'>
						<Button
						 	className='toolsSelector-panel-actions-button'
							label='Clear'
							onClick={this.onClearFilters}
						/>
						<Button
						 	className='toolsSelector-panel-actions-button'
							label='Material Libraries'
							onClick={this.onToggleShowMaterialSelection}
						/>
					</div>
				</div>
				<div className='toolsSelector-list'>
				<Table
					headers={
						[
							{
								label: 'CatalogNo',
								field: 'HeaderNo',
							},
							{
								label: 'Hole Type',
								field: 'Hole',
								type: 'render',
								props: {
									render: function(item){
										var iconsToRender = {};
										item.workConditions.forEach(function(workCondition, workCondition_index){
											if(iconsToRender[workCondition.techCaracteristic.icon.id] === undefined){
												iconsToRender[workCondition.techCaracteristic.icon.id] = workCondition.techCaracteristic.icon;
											}
										})
										return (
											<div>
												{Object.values(iconsToRender).map(function(icon, index){
													return (
														<img
															style={{width: '30px', height: '30px'}} 
															src={process.env.PUBLIC_URL+'/ICON/'+icon.FileName+'.'+icon.IconFileExtension} 
															alt={icon.FileName} 
															key={item.id+'_'+index}
														/>
													)
												})}
											</div>
										)
										
									}
								}
							},
							{
								label: 'Standard',
								field: 'Hole',
								type: 'img',
								props: {
									PositionCode:'H-S1',
									Type: 'ICON'
								}
							},
							{
								label: 'Picture',
								field: 'Picture',
								type: 'img',
								props: {
									PositionCode:'H-IMAGE',
									Type: 'IMAGE'
								}
							},
							{
								label: 'Thread',
								field: 'Standard',
								type: 'img',
								props: {
									PositionCode:'H-1A',
									Type: 'ICON'
								}
							},
							{
								label: 'Form',
								field: 'Form',
								type: 'img',
								props: {
									PositionCode:'H-3A',
									Type: 'ICON'
								}
							},
							{
								label: 'Flutes',
								field: 'Flutes',
								type: 'img',
								props: {
									PositionCode:'H-3B',
									Type: 'ICON'
								}
							},
							{
								label: 'Tol.',
								field: 'Tol.',
								type: 'img',
								props: {
									PositionCode:'H-TOL',
									Type: 'ICON'
								}
							},
							{
								label: 'Coating',
								field: 'Coating',
								type: 'img',
								props: {
									PositionCode:'H-3C',
									Type: 'ICON'
								}
							},
						]
					}
					
					data={
						this.props.dataReducer.headers !== undefined ? Object.values(this.props.dataReducer.headers).filter(filterFunction) : []
					}
					onSelectionChange={function(selection){
						that.onSelectItem(selection)
					}}
				/>
				</div>
			</div>
		);
	}
	renderItem = () => {
		var that = this;

		
		const images_data = this.state.selectedItem.headersPositions.filter(headerPosition => headerPosition.icon.Type === 'IMAGE');
		const icons_data = this.state.selectedItem.headersPositions.filter(headerPosition => headerPosition.icon.Type === 'ICON');
		const drawing_data = this.state.selectedItem.headersPositions.filter(headerPosition => headerPosition.icon.Type === 'DRAWING');
		var tableHeaders = [
			{
				label: 'd1',
				field: 'd1',
			},
			{
				label: 'P',
				field: 'P',
			},
			{
				label: 'l1',
				field: 'L1',
			},
			{
				label: 'l2',
				field: 'L2',
			},
			{
				label: 'l3',
				field: 'L3',
			},
			{
				label: 'd2',
				field: 'd2',
			},
			{
				label: 'l',
				field: 'L',
			},
			{
				label: 'a',
				field: 'a',
			},
			{
				label: 'z',
				field: 'Z',
			},
			{
				label: 'DrillSize',
				field: 'DrillSize',
			},
			{
				label: 'Code',
				field: 'ArticleNo',
			},
		];
		if(this.props.sessionReducer.loged){
			tableHeaders.push({
				label: 'Stock',
				field: 'Stock',
				width: '80px',
				props: {
					'suffix': ''
				}
			});
			tableHeaders.push({
				label: 'Price',
				field: 'Price',
				width: '80px',
				type: 'money',
				props: {
					'suffix': ''
				}
			});
		}
		return (
			<div className='item'>
				<div className='item-subnav'>
					<div className='item-subnav-left-box'>
						<div className='item-subnav-return-box' onClick={this.onCloseItem}>
							<img className='item-subnav-icon' src={leftChevronRedIcon} alt='' />
							Back
						</div>
					</div>
					<div className='item-subnav-center-box'>

					</div>
					<div className='item-subnav-right-box'>
					</div>
				</div>
				<div className='item-content'>
					<div className='item-content-header'>
						<div className='item-content-header-left'>
							<div className='item-content-header-headerNo'>
								<div className='item-content-header-headerNo-title'>
									Catalog Number
								</div>
								<div className='item-content-header-headerNo-value'>
									{	
										this.state.selectedItem.HeaderNo !== undefined && capitalizeTheFirstLetterOfEachWord(this.state.selectedItem.HeaderNo)
									}
								</div>
							</div>
							<div className='item-content-header-category'>
								<div className='item-content-header-category-title'>
									Category Code
								</div>
								<div className='item-content-header-category-value'>
									{	
										this.state.selectedItem.CategoryCode !== undefined && capitalizeTheFirstLetterOfEachWord(this.state.selectedItem.CategoryCode)
									}
								</div>
							</div>
							<div className='item-content-header-type'>
								<div className='item-content-header-type-title'>
									Thread Type
								</div>
								<div className='item-content-header-type-value'>
									{this.state.selectedItem.T2}
								</div>
							</div>
						</div>
						<div className='item-content-header-center'>
							<div className='item-content-header-img'>
								<img src={images_data[0] !== undefined && process.env.PUBLIC_URL + '/IMAGE/'+images_data[0].icon.FileName+'.'+images_data[0].icon.IconFileExtension} alt=''/>
							</div>
							{
								(images_data.length > 1 &&
									<div className='item-content-header-img'>
										<img src={process.env.PUBLIC_URL + '/IMAGE/'+images_data[1].icon.FileName+'.'+images_data[1].icon.IconFileExtension} alt=''/>
									</div>
								)
							}

							<div className='item-content-header-diagram'>
								<img src={drawing_data[0] !== undefined && process.env.PUBLIC_URL + '/DRAWING/'+drawing_data[0].icon.FileName+'.'+drawing_data[0].icon.IconFileExtension} alt=''/>
							</div>
						</div>
						<div className='item-content-header-right'>
						<div className='item-content-header-features'>
								<div className='item-content-header-features-row'>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-1A') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-1A').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-1A').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-1B') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-1B').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-1B').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-1C') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-1C').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-1C').icon.IconFileExtension : featureIcon} alt=''/>
								</div>
								<div className='item-content-header-features-row'>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-3A') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-3A').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-3A').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-3B') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-3B').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-3B').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-3C') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-3C').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-3C').icon.IconFileExtension : featureIcon} alt=''/>
								</div>
								<div className='item-content-header-features-row'>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-4A') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-4A').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-4A').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-4B') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-4B').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-4B').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-4C') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-4C').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-4C').icon.IconFileExtension : featureIcon} alt=''/>
								</div>
								<div className='item-content-header-features-row'>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-S1') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-S1').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-S1').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-S2') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-S2').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-S2').icon.IconFileExtension : featureIcon} alt=''/>
									<img src={icons_data.find(item => item.position.PositionCode === 'H-TOL') !== undefined ? process.env.PUBLIC_URL + '/ICON/'+icons_data.find(item => item.position.PositionCode === 'H-TOL').icon.FileName+'.'+icons_data.find(item => item.position.PositionCode === 'H-TOL').icon.IconFileExtension : featureIcon} alt=''/>
								</div>
							</div>
						</div>
					</div>
					<div className='item-content-articlesData'>
					<Table
						headers={tableHeaders}
						sorterField='DrillSize'
						data={
							Object.values(this.state.selectedItem.articles)
						}
						onSelectionChange={function(selection){
							that.onSelectArticle(selection)
						}}
						search={false}
					/>
					</div>
					<div className='item-content-speedData'>
					<Table
						headers={[
							{
								label: 'Suitability',
								field: 'SuitabilityID',
								type: 'suitability',
							},
							{
								label: 'Mat. Class',
								field: 'MaterialClasseID',
								type: 'render',
								props: {
									render: function(item){
										
										return (
											<img 
												style={{width: '30px', height: '30px'}} 
												src={process.env.PUBLIC_URL+'/ICON/'+item.materialClasse.icon.FileName+'.'+item.materialClasse.icon.IconFileExtension} 
												alt={item.materialClasse.icon.FileName} 
											/>
										)
										
									}
								}
							},
							{
								label: 'Hole Type',
								field: 'HoleTypeID',
								type: 'render',
								props: {
									render: function(item){
										return (
											<img 
												style={{width: '30px', height: '30px'}} 
												src={process.env.PUBLIC_URL+'/ICON/'+Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === Object.values(that.props.dataReducer.technicalCaracteristics).find(techCar => techCar.TechCarID === item.HoleTypeID).IconID).FileName
												+'.'+
												Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID).IconFileExtension} 
												alt={Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID) !== undefined && 
													Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID).FileName+'.'+Object.values(that.props.dataReducer.icons).find(icon => icon.IconID === item.HoleTypeID).IconFileExtension
												} 
											/>
										)
										
									}
								}
							},
							{
								label: 'Minimum',
								field: 'FromSpeed',
								props: {
									'suffix': 'm/min'
								}
							},
							{
								label: 'Recommended ',
								type: 'average',
								props: {
									'suffix': 'm/min',
									data:[
										'FromSpeed',
										'ToSpeed'
									],
								}
							},
							{
								label: 'Maximum',
								field: 'ToSpeed',
								props: {
									'suffix': 'm/min'
								}
							},
							
							
						]}
						data={this.state.selectedItem.workConditions}
						search={false}
					/>
					</div>
				</div>
			</div>
		)
	}
	render() {
		return this.state.showMaterialSelector ?  this.renderMaterials() : this.state.selectedItem !== null ? this.renderItem() : this.renderSelector();
	}
}
function capitalizeTheFirstLetterOfEachWord(words) {
   var separateWord = words.toLowerCase().split(' ');
   for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1);
   }
   return separateWord.join(' ');
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(ToolSelectorLayout);
