import React from 'react';
import '../../styles/layouts/error404.scss';


import { connect } from 'react-redux'

class Error404 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
			return(
				<div className='error_404'>
					<div className='error_404-box'>
						<div className='error_404-box-title'>
								Page not fund !
						</div>
						<div className='error_404-description'>
								Please check url or advice system administrator
						</div>
					</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(Error404);
