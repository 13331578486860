import React from 'react';
import '../styles/components/content.scss';

import { connect } from 'react-redux'

import Error404 from '../layouts/404/error404.js';
import Home from '../layouts/home/home.js';
import ToolSelector from '../layouts/toolSelector/toolSelector.js';
import Articles from '../layouts/articles/articles.js';
import Article from '../layouts/article/article.js';
import TechnicalCharacteristics from '../layouts/technicalCharacteristicsAdmin/technicalCharacteristicsAdmin.js';
import Users from '../layouts/users/users.js';
import NewHeadersAdmin from '../layouts/newHeadersAdmin/newHeadersAdmin.js';
import HeadersAdmin from '../layouts/headersAdmin/headersAdmin.js';
import ArticleAdmin from '../layouts/articlesAdmin/articlesAdmin.js';
import MaterialsAdmin from '../layouts/materialsAdmin/materialsAdmin.js';
import MaterialsCategoryAdmin from '../layouts/materialsCategoryAdmin/materialsCategoryAdmin.js';
import MaterialsClassesAdmin from '../layouts/materialsClassesAdmin/materialsClassesAdmin.js';
import MaterialsTypeAdmin from '../layouts/materialsTypeAdmin/materialsTypeAdmin.js';
import Register from '../layouts/register/register.js';
import Validation from '../layouts/validation/validation.js';
import Test from '../layouts/test/test.js';

class Content extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		var to_render = (
			<div className='content' ><Error404 /></div>
		);
		var path = window.location.pathname.split('/')[1] !== '' ? this.props.interfaceReducer.currentPage === '' ? window.location.pathname.split('/')[1] : this.props.interfaceReducer.currentPage : 'home';
		path = window.location.pathname.split('/')[1] === 'validation' ? 'validation' : path;
		switch (path) {
			case 'home':
				to_render = (
					<div className='content' ><Home /></div>
				);
				break;
			case 'tool-selector':
				to_render = (
					<div className='content' ><ToolSelector /></div>
				);
			break;
			case 'articles':
				to_render = (
					<div className='content' ><Articles /></div>
				);
			break;
			case 'article':
				to_render = (
					<div className='content' ><Article /></div>
				);
			break;
			case 'new-headers-admin':
				to_render = (
					<div className='content' ><NewHeadersAdmin /></div>
				);
			break;
			case 'headers-admin':
				to_render = (
					<div className='content' ><HeadersAdmin /></div>
				);
			break;
			case 'technical-characteristics-admin':
				to_render = (
					<div className='content' ><TechnicalCharacteristics /></div>
				);
			break;
			case 'users':
				to_render = (
					<div className='content' ><Users /></div>
				);
				break;
			case 'articles-admin':
				to_render = (
					<div className='content' ><ArticleAdmin /></div>
				);
				break;
			case 'materials-admin':
				to_render = (
					<div className='content' ><MaterialsAdmin /></div>
				);
				break;
			case 'materials-category-admin':
				to_render = (
					<div className='content' ><MaterialsCategoryAdmin /></div>
				);
				break;
			case 'materials-classes-admin':
				to_render = (
					<div className='content' ><MaterialsClassesAdmin /></div>
				);
				break;
			case 'materials-type-admin':
				to_render = (
					<div className='content' ><MaterialsTypeAdmin /></div>
				);
				break;
			case 'register':
				to_render = (
					<div className='content' ><Register /></div>
				);
				break;
			case 'validation': 
				to_render = (
					<div className='content' ><Validation /></div>
				);
				break;
			case 'test':
				to_render = (
					<div className='content' ><Test /></div>
				);
				break;
			default:
				break;
		}
		return to_render;
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(Content);
