import React from 'react';
import Modal from '@fvillard/reactmodal';
import Input from '../modules/react-input/src/index.js';
import Button from '@fvillard/reactbutton';

import '../styles/components/mainNavigation.scss';
import logo from '../assets/img/logo.png';

import toolSelectorIcon from '../assets/img/mainNavigation/toolSelector.png';
import homeIcon from '../assets/img/mainNavigation/home.png';
import loginIcon from '../assets/img/mainNavigation/login.png';
import { FaCog, FaExpand, FaUser, FaPowerOff, FaInfo, FaUserFriends, FaCompress, FaTools, FaFileAlt, FaReact, FaBuffer, FaBoxes, FaFlag, FaShoppingCart } from 'react-icons/fa';

import { connect } from 'react-redux'

class MainNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoginMenu: false,
			showSettingsMenu: false,
			showProfilModal: false,
			showAboutModal: false,
			showSettingsModal: false,

			email: 'fvillard@coachit.ch',
			email_error: null, //undefined,
			password: 'pass',
			password_error: null, //undefined,

			name: this.props.sessionReducer.account !== undefined ? this.props.sessionReducer.account.Name : '',
			name_error: null, //undefined,
			surname: this.props.sessionReducer.account !== undefined ? this.props.sessionReducer.account.Surname : '',
			surname_error: null, //undefined,
		};

	}
	toggleShowLoginMenu = () => {
		this.setState({showLoginMenu: this.state.showLoginMenu ? false : true});
	}
	toggleShowSettingsMenu = () => {
		this.setState({showSettingsMenu: this.state.showSettingsMenu ? false : true});
	}
	toggleShowProfilModal = () => {
		this.setState({showProfilModal: this.state.showProfilModal ? false : true, showSettingsMenu: this.state.showProfilModal ? true : false});
	}
	toggleShowAboutModal = () => {
		this.setState({showAboutModal: this.state.showAboutModal ? false : true, showSettingsMenu: this.state.showAboutModal ? true : false});
	}
	toggleShowSettingsModal = () => {
		this.setState({showSettingsModal: this.state.showSettingsModal ? false : true, showSettingsMenu: this.state.showSettingsModal ? true : false});
	}
	onLinkClick = (id) => {
		const action = { type: "CHANGE_CURRENT_PAGE", value: id, socket: {
			send: false
		}}
		this.props.dispatch(action)
	}
	onChangeLogin = (id,value,error) => {
		var state = this.state;
		state[id] = value;
		state[id+'_error'] = error;
		this.setState(state);
	}
	onChangeProfil = (id,value,error) => {
		var state = this.state;
		state[id] = value;
		state[id+'_error'] = error;
		this.setState(state);
	}
	onLoginKeyDown = (event) => {
		if(event.keyCode === 13){
			this.state.email_error === null && this.state.password_error === null && this.onLogin()
		}
	}
	onResetPassword = () => {
		if((this.state.email !== '' && this.state.email_error === null) || this.state.email_error === ''){
			const action = { type: "resetPassword", value: {Email:this.state.email}, noSave: true, socket: {
				send: true
			}}
			this.props.dispatch(action);
		}
	}
	onLogin = () => {
		const action = { type: "login", value: {Email:this.state.email,Password:this.state.password}, noSave: true, socket: {
			send: true
		}}
		this.props.dispatch(action);
		this.toggleShowLoginMenu();
	}
	onSave = () => {
		const action = { type: "save account", value: {id:this.props.sessionReducer.account.id,Email:this.state.email,Password:this.state.password,Name:this.state.name,Surname:this.state.surname}, socket: {
			send: true
		}}
		this.props.dispatch(action);
		this.toggleShowSettingsMenu();

	}
	render() {
		var that = this;
		return(
			<div className='mainNavigation'>
				<div className='mainNavigation-logo' onClick={function(){that.onLinkClick('home')}}>
					<img className='mainNavigation-logo-img' src={logo} alt=''/>
				</div>
				<div className='mainNavigation-nav'>
					<div className={this.props.interfaceReducer.currentPage === 'tool-selector' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
						onClick={function(){that.onLinkClick('tool-selector')}}
					>
						<img className='mainNavigation-nav-item-icon' src={toolSelectorIcon} alt='' />
						<div className='mainNavigation-nav-item-text'>
							Tool Selector
						</div>
					</div>
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'test' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('test')}}
						>
							<FaTools className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'articles-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('articles-admin')}}
						>
							<FaShoppingCart className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Articles
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'headers-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('headers-admin')}}
						>
							<FaFileAlt className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Headers
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'new-headers-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('new-headers-admin')}}
						>
							<FaFileAlt className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								New Headers
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'materials-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('materials-admin')}}
						>
							<FaReact className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Materials
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'materials-category-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('materials-category-admin')}}
						>
							<FaBuffer className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Mat cat.
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'materials-classes-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('materials-classes-admin')}}
						>
							<FaBoxes className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Mat classes
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'materials-type-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('materials-type-admin')}}
						>
							<FaFlag className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Mat Type
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'technical-characteristics-admin' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('technical-characteristics-admin')}}
						>
							<FaFileAlt className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Tech Char
							</div>
						</div>
					)}
					{this.props.sessionReducer.loged && (
						<div className={this.props.interfaceReducer.currentPage === 'users' ? 'mainNavigation-nav-item mainNavigation-nav-item--active' : 'mainNavigation-nav-item'}
							onClick={function(){that.onLinkClick('users')}}
						>
							<FaUserFriends className='mainNavigation-nav-item-icon' />
							<div className='mainNavigation-nav-item-text'>
								Users
							</div>
						</div>
					)}

				</div>
				<div className='mainNavigation-actions'>
					<div className='mainNavigation-actions-item' title='Parametre' onClick={function(){that.onLinkClick('home')}}>
						<img className='mainNavigation-actions-item-icon' src={homeIcon} alt='' />
						<div className='mainNavigation-actions-item-text'>
							Home
						</div>
					</div>
					{this.props.sessionReducer.loged ? (
						<div className='mainNavigation-actions-item' title='Parametre' onClick={this.toggleShowSettingsMenu}>
							<FaCog

							/>
						</div>
					) : (

						<div className='mainNavigation-actions-item' title='Parametre' onClick={this.toggleShowLoginMenu}>
							<img className='mainNavigation-actions-item-icon' src={loginIcon} alt='' />
							<div className='mainNavigation-actions-item-text'>
								Login
							</div>
						</div>
					)}
					<div className='mainNavigation-actions-item' title='Plein écran' onClick={this.props.handleFullScreen.active ? this.props.handleFullScreen.exit : this.props.handleFullScreen.enter}>
						{
							this.props.handleFullScreen.active ? (
								<FaCompress

								/>
							) :
							(
								<FaExpand

								/>
							)
						}
					</div>
				</div>
				<div className={this.state.showSettingsMenu === false ? 'mainNavigation-popup' : 'mainNavigation-popup mainNavigation-popup--showed'}>
					<div className='mainNavigation-popup-item' onClick={this.toggleShowProfilModal}>
						<FaUser className='mainNavigation-popup-item-icon' />
						<div className='mainNavigation-popup-item-text'>
							Mon profil
						</div>
					</div>
					<div className='mainNavigation-popup-item' onClick={this.toggleShowAboutModal}>
						<FaInfo className='mainNavigation-popup-item-icon' />
						<div className='mainNavigation-popup-item-text'>
							A propos
						</div>
					</div>
					<div className='mainNavigation-popup-item' onClick={this.toggleShowSettingsModal}>
						<FaCog className='mainNavigation-popup-item-icon' />
						<div className='mainNavigation-popup-item-text'>
							Préférences
						</div>
					</div>
					<div className='mainNavigation-popup-item' onClick={this.state.showSettingsMenu ? function(){
						that.toggleShowSettingsMenu();
						that.onLinkClick('home');
						const action = { type: "SIGNOUT", socket: {
							send: false
						}}
						that.props.dispatch(action)
					} : function(){}}>
						<FaPowerOff className='mainNavigation-popup-item-icon' />
						<div className='mainNavigation-popup-item-text'>
							Déconnection
						</div>
					</div>
				</div>
				<div className={this.state.showLoginMenu === false ? 'mainNavigation-popup' : 'mainNavigation-popup mainNavigation-popup--showed mainNavigation-popup-lg'}>
					<div className='mainNavigation-popup-login'>
						<Input
							id='email'
							label='Email'
							type='email'
							value={this.state.email}
							error=''
							onChange={this.onChangeLogin}
							onKeyDown={this.onLoginKeyDown}
							required
						/>
						<Input
							id='password'
							label='Mot de passe'
							type='password'
							value={this.state.password}
							error=''
							onChange={this.onChangeLogin}
							onKeyDown={this.onLoginKeyDown}
							required
						/>
						<Button
							className='mainNavigation-modal-button'
							label='Confirm'
							onClick={this.state.email_error === null && this.state.password_error === null ? this.onLogin : function(){}}
						/>
						<Button
							className='mainNavigation-modal-button'
							style={{marginTop: '10px'}}
							label='Forgot your password'
							onClick={this.onResetPassword}
						/>
						<Button
							className='mainNavigation-modal-button'
							style={{marginTop: '10px'}}
							label='Sign up'
							onClick={function(){
								that.toggleShowLoginMenu();
								const action = { type: "CHANGE_CURRENT_PAGE", value: 'register', socket: {
									send: false
								}}
								that.props.dispatch(action)
							}}
						/>
					</div>
				</div>
				<Modal
					className='mainNavigation-modal mainNavigation-modal--sm'
					title='Mon profil'
					body={(
						<div>
							<Input
								id='email'
								label='Email'
								type='email'
								value={this.props.sessionReducer.account !== undefined ? this.props.sessionReducer.account.Email : ''}
								disabled
							/>
							<Input
								id='password'
								label='Mot de passe'
								type='password'
								onChange={this.onChangeProfil}
							/>
							<Input
								id='name'
								label='Nom'
								value={this.state.name}
								onChange={this.onChangeProfil}
							/>
							<Input
								id='surname'
								label='Prénom'
								value={this.state.surname}
								onChange={this.onChangeProfil}
							/>
							<Button
								className='mainNavigation-modal-button'
								label='Enregistrer'
								onClick={this.onSave}
							/>
						</div>
					)}
					show={this.state.showProfilModal}
					onClose={this.toggleShowProfilModal}
				/>
				<Modal
					className='mainNavigation-modal mainNavigation-modal--sm'
					title='A propos'
					body={(
						<div>
							<Input
								label='Token'
								value={this.props.sessionReducer.token}
								disabled
							/>
							<Button
								className='mainNavigation-modal-button'
								label='Fermer'
								onClick={this.toggleShowAboutModal}
							/>
						</div>
					)}
					show={this.state.showAboutModal}
					onClose={this.toggleShowAboutModal}
				/>
				<Modal
					className='mainNavigation-modal mainNavigation-modal--sm'
					title='Préférences'
					show={this.state.showSettingsModal}
					onClose={this.toggleShowSettingsModal}
				/>
			</div>
		);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(MainNavigation);
