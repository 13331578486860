import React from 'react';
import '../../styles/layouts/validation.scss';

import { connect } from 'react-redux'

class ValidationLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
        if(window.location.pathname.split('/')[2] !== undefined){
            const action = { type: "valid account", value: {validationToken: window.location.pathname.split('/')[2]}, noSave: true, socket: {
                send: true
            } }
            this.props.dispatch(action);
        }
	}
	render() {
		//var that = this;
		return(
			<div className='validation'>
				Validation in progress...
			</div>
            
		);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(ValidationLayout);
