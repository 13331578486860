import React from 'react';
import Table from '../../modules/react-table/src/index';

import '../../styles/layouts/articles.scss';


import { connect } from 'react-redux'

class Articles extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		const action = { type: "get articles", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action);
	}
	render() {
			var tableHeaders = [
				{
					label: 'ArticleNo',
					field: 'ArticleNo',
				},
				{
					label: 'HeaderNo',
					field: 'HeaderNo',
				},
				{
					label: 'd1',
					field: 'd1',
				},
				{
					label: 'P',
					field: 'P',
				},
				{
					label: 'ThreadType',
					field: 'ThreadType',
				},
				{
					label: 'L1',
					field: 'L1',
				},
				{
					label: 'L2',
					field: 'L1',
				},
				{
					label: 'L3',
					field: 'L1',
				},
				{
					label: 'd2',
					field: 'd2',
				},
				{
					label: 'dn',
					field: 'dn',
				},
				{
					label: 'L',
					field: 'L',
				},
				{
					label: 'a',
					field: 'a',
				},
				{
					label: 'Z',
					field: 'Z',
				},
				{
					label: 'R_F',
					field: 'R_F',
				},
				{
					label: 'HandTapType',
					field: 'R_F',
				},
				{
					label: 'DrillSize',
					field: 'DrillSize',
				},
			];
			if(this.props.sessionReducer.loged){
				tableHeaders.splice(2, 0, {
					label: 'Stock',
					field: 'Stock',
				});
				tableHeaders.splice(3, 0, {
					label: 'Price',
					field: 'Price',
					type: 'money',
					props: {
						'suffix': '€'
					}
				});
			}

			return(
				<div className='articles'>
          <Table
						headers={tableHeaders}

						data={
							this.props.dataReducer.articles !== undefined ? Object.values(this.props.dataReducer.articles) : []
						}
						onSelectionChange={function(selection){
							
						}}
					/>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(Articles);
