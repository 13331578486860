import React from 'react';
import Input from '@fvillard/reactinput';
import Select from  '@fvillard/reactselect';
import './index.scss';

class Repeater extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			types: props.types !== undefined ? props.types : ['text'],
			values: props.values !== undefined ? props.values : [''],
			errors: props.errors !== undefined ? props.errors : [''],
			items: props.items !== undefined ? this.parseData(props.items) : [],
		};
	}
	createObject(items){
		var that = this;
		var result = [];
		
		items.forEach(function(item, index){
			result[index] = {};
			result[index]['id'] = item.id;
			that.props.ids.forEach(function(id, id_index){
				if(item[id].value !== undefined && item[id].error !== undefined){
					result[index][id] = {value: item[id].value, error: item[id].error};
				}
				else{
					result[index][id] = {value: item[id], error: false};
				}
			});
		});
		return result;
	}
	parseData(items){
		var that = this;
		var result = [];
		items.forEach(function(item, index){
			result[index] = {};
			that.props.ids.forEach(function(id){
				if(item[id].value === undefined){
					result[index][id] = {value: items[index][id], error: ''};
				}
				else{
					result[index][id] = item[id];
				}
			});
		})
		return result;
	}
	addItem(){
		var newItem = {};
		var values = this.state.values;
		var errors = this.state.errors;
		this.props.ids.forEach(function(item, index){
			newItem[item] = {value: !isNaN(parseInt(values[index])) ? parseInt(values[index]) : values[index],error: errors[index] !== null ? errors[index] : ''};
			values[index] = '';
			errors[index] = '';
		});	
		this.setState({values: values, errors: errors});
		this.props.id !== undefined && this.props.onChange !== undefined && this.state.errors.find(error => error !== '') === undefined && this.props.onChange(this.props.id, this.createObject(this.props.items.concat([newItem])), false)
	}
	removeItem(index){
		this.setState({
			items: this.state.items.filter((_, i) => i !== index)
		  });
		this.props.id !== undefined && this.props.onChange !== undefined && this.props.onChange(this.props.id, this.createObject(this.props.items.filter((_, i) => i !== index)), false)
	}
	onChangeElement = (id, value, error) => { 
		const index = this.props.ids.indexOf(id);
		var values = this.state.values;
		var errors = this.state.errors;	
		if(values[index] === undefined){
			values[index] = [];
			errors[index] = [];
		}
		values[index] = value;
		errors[index] = error;
		this.setState({
			values: values,
			errors: errors
		});
	}
	onChangeItem = (index, type_index, id, value, error) => {	
		var items = this.props.items;
		if(items[index][this.props.ids[type_index]] === undefined){
			items[index][this.props.ids[type_index]] = {};
		}
		items[index][this.props.ids[type_index]].value = value;
		items[index][this.props.ids[type_index]].error = error;
		this.props.id !== undefined && this.props.onChange !== undefined && this.state.errors.find(error => error !== '') === undefined && this.props.onChange(this.props.id, this.createObject(items), false)
	}
	renderElement(){
		return (
			<div className='repeaterBox-renderBox-inputsBox'>
			{this.state.types.map((item, index) =>	
				{
					switch(item){
						case "select": return (
							<div className='repeaterBox-renderBox-inputsBox-item' key={'element_select_'+index}>
								<Select 
									id={this.props.ids[index]}
									placeHolder={this.props.placeHolders[index] !== undefined ? this.props.placeHolders[index] : ''}
									value={this.state.values[index] !== undefined && !this.props.props[index].required && this.state.values[index]}
									error={this.state.errors[index] !== undefined ? this.state.errors[index] : ''}
									options={this.props.props[index] !== undefined && this.props.props[index].options !== undefined ? this.props.props[index].options : [{label: '', value: 0}]}
									onChange={this.onChangeElement}
									required={this.props.props[index].required !== undefined ? this.props.props[index].required : false}
									disabled={this.props.disabled ? this.props.disabled : false}
								/>
							</div>
							)
						break;
						default:
							return (
								<div className='repeaterBox-renderBox-inputsBox-item' key={'element_input_'+index}>
									<Input 
										id={this.props.ids[index]}
										placeHolder={this.props.placeHolders[index] !== undefined ? this.props.placeHolders[index] : ''}
										value={this.state.values[index] !== undefined ? this.state.values[index] : ''}
										error={this.state.errors[index] !== undefined ? '' : ''}
										onChange={this.onChangeElement}
										required={this.props.props[index].required !== undefined ? this.props.props[index].required : false}
										min={this.props.props[index].min !== undefined ? this.props.props[index].min : false}
										max={this.props.props[index].max !== undefined ? this.props.props[index].max : false}
										disabled={this.props.disabled ? this.props.disabled : false}
									/>
								</div>
							)
						break;
					}
				}	
			)}
			</div>
		)
	}
	renderItem(index, item){
		var that = this;
		return (
			<div className='repeaterBox-list-item-renderBox-inputsBox'>
			{this.state.types.map((type_item, type_index) =>	
				{
					switch(type_item){
						case "select": 
							return (
								<div className='repeaterBox-list-item-renderBox-inputsBox-item' key={'input_'+this.props.ids[type_index]+'_'+index}>
								<Select 
									id={this.props.ids[type_index]}
									placeHolder={this.props.placeHolders[type_index]}
									value={Object.values(item)[type_index] !== undefined ? Object.values(item)[type_index].value : ''}
									error={Object.values(item)[type_index] !== undefined ? Object.values(item)[type_index].error : ''}
									options={this.props.props[type_index] !== undefined && this.props.props[type_index].options !== undefined ? this.props.props[type_index].options : [{label: '', value: 0}]}
									onChange={function(id, value, error){
										that.onChangeItem(index, type_index, id, value, error)
									}}
									required={this.props.props[type_index].required !== undefined ? this.props.props[type_index].required : false}
									disabled={this.props.disabled ? this.props.disabled : false}
								/>
								</div>
								)
						break;
						default: 
							return (
								<div className='repeaterBox-list-item-renderBox-inputsBox-item' key={'input_'+this.props.ids[type_index]+'_'+index}>
								<Input 
									id={this.props.ids[type_index]}
									placeHolder={this.props.placeHolders[type_index]}
									value={Object.values(this.state.items[index])[type_index] !== undefined ? Object.values(this.state.items[index])[type_index].value : ''}
									error={Object.values(this.state.items[index])[type_index] !== undefined ? Object.values(this.state.items[index])[type_index].error : ''}
									onChange={function(id, value, error){
										that.onChangeItem(index, type_index, id, value, error)
									}}
									required={this.props.props[type_index].required !== undefined ? this.props.props[type_index].required : false}
									min={this.props.props[type_index].min !== undefined ? this.props.props[type_index].min : false}
									max={this.props.props[type_index].max !== undefined ? this.props.props[type_index].max : false}
									disabled={this.props.disabled ? this.props.disabled : false}
								/>
								</div>
							)
						break;
					}
				}	
			)}
			</div>
		)
	}
	render() {
		var that = this;
		var items = this.props.items !== undefined ? this.parseData(this.props.items) : this.state.items;
		return (
			<div className='repeaterBox'>
				<div className='repeaterBox-renderBox'>
					{this.renderElement()}
				</div>
				<div className='repeaterBox-actionBox'>
					<div 
						className='repeaterBox-actionBox-action'
						onClick={this.props.disabled === undefined ||  this.props.disabled === false ? function(){that.addItem()} : function(){}}
					>
						+
					</div>
				</div>
				<div className='repeaterBox-list'>
					{items !== undefined && items.map((item, index) =>
						<div className="repeaterBox-list-item" key={'item_'+index}>
							<div className='repeaterBox-list-item-renderBox'>
								{this.renderItem(index, item)}
							</div>
							<div className='repeaterBox-list-item-actionBox'>
								<div 
									className='repeaterBox-list-item-actionBox-action'
									onClick={this.props.disabled === undefined ||  this.props.disabled === false ? function(){that.removeItem(index)} : function(){}}
								>
									-
								</div>
							</div>	
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default Repeater;