import React from 'react';
import ReactInput from '../../modules/react-input/src/index.js';
import '../../styles/layouts/test.scss';

import { connect } from 'react-redux'

class TestLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
  generateCatalogFile = () => {
    const action = { 
      type: "generateCatalogFile", 
      value: {
    
      }, 
      noSave: true, 
      socket: {
			  send: true
      }
    }
		this.props.dispatch(action);
  }
  exportTable = (name) => {
    const action = { 
      type: "exportTable", 
      value: {
        name: name
      }, 
      noSave: true, 
      socket: {
			  send: true
      }
    }
		this.props.dispatch(action);
  }
  importTable = (id,value,error) => {
    console.log(value);
  }
	render() {
    var that = this;
        return(
            <div className='test'>
              <div className='column'>
                <div className='button' onClick={this.generateCatalogFile}>
                  Generate Catalog Data
                </div>
              </div>
              <div className='column'>
                <div className='button' onClick={function(){that.exportTable('Articles')}}>
                  Export articles table
                </div>
                <div className='button' onClick={function(){that.exportTable('Headers')}}>
                  Export headers table
                </div>
                <div className='button' onClick={function(){that.exportTable('HeadersPositions')}}>
                  Export headerspositions table
                </div>
                <div className='button' onClick={function(){that.exportTable('Icons')}}>
                  Export icons table
                </div>
                <div className='button' onClick={function(){that.exportTable('Materials')}}>
                  Export materials table
                </div>
                <div className='button' onClick={function(){that.exportTable('MaterialsCategories')}}>
                  Export materialscategories table
                </div>
                <div className='button' onClick={function(){that.exportTable('MaterialsClasses')}}>
                  Export materialsclasses table
                </div>
                <div className='button' onClick={function(){that.exportTable('MaterialsTypes')}}>
                  Export materialstypes table
                </div>
                <div className='button' onClick={function(){that.exportTable('Positions')}}>
                  Export positions table
                </div>
                <div className='button' onClick={function(){that.exportTable('Suitabilities')}}>
                  Export suitabilities table
                </div>
                <div className='button' onClick={function(){that.exportTable('TechCaracteristics')}}>
                  Export techcaracteristics table
                </div>
                <div className='button' onClick={function(){that.exportTable('WorkConditions')}}>
                  Export workconditions table
                </div>
              </div>
              <div className='column'>
                <ReactInput 
                  id='articles'
                  placeHolder='articles'
                  type='file'
                  onChange={this.importTable}
                />
                <ReactInput 
                  id='headers'
                  placeHolder='headers'
                  type='file'
                />
                <ReactInput 
                  id='headerspositions'
                  placeHolder='headerspositions'
                  type='file'
                />
                <ReactInput 
                  id='icons'
                  placeHolder='icons'
                  type='file'
                />
                <ReactInput 
                  id='materials'
                  placeHolder='materials'
                  type='file'
                />
                <ReactInput 
                  id='materialscategories'
                  placeHolder='materialscategories'
                  type='file'
                />
                <ReactInput 
                  id='materialsclasses'
                  placeHolder='materialsclasses'
                  type='file'
                />
                <ReactInput 
                  id='materialstypes'
                  placeHolder='materialstypes'
                  type='file'
                />
                <ReactInput 
                  id='positions'
                  placeHolder='positions'
                  type='file'
                />
                <ReactInput 
                  id='suitabilities'
                  placeHolder='suitabilities'
                  type='file'
                />
                <ReactInput 
                  id='techcaracteristics'
                  placeHolder='techcaracteristics'
                  type='file'
                />
                <ReactInput 
                  id='workconditions'
                  placeHolder='workconditions'
                  type='file'
                />
              </div>
            </div>
        );
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(TestLayout);
