import React from 'react';
import {BsChevronUp, BsChevronDown} from "react-icons/bs";
import './index.scss';

import Input from '../../react-input/src/index';

class Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: '',
			searchFunction: function(obj){
				if(obj.value !== undefined || obj.value !== ''){
					return true;
				}
				else{
					return false;
				}
			},
			showOptionsList: false
		};
	}
	onToggleShowOptionsList = () => {
		this.setState({showOptionsList: this.state.showOptionsList ? false : true});
	}
	onChangeInput = (id, value, error) => {
		var func = function(obj){
			if(obj.value !== undefined || obj.value !== ''){
				return true;
			}
			else{
				return false;
			}
		}
		if(value !== ''){
			func = function(obj){
				// eslint-disable-next-line
				var evalL = eval;
				if(obj.value !== undefined && obj.label.toString().toUpperCase().match(evalL('/'+value.toString().toUpperCase()+'/'))){
					return true;
				}
				else{
					return false;
				}
			}
		}
		this.setState({showOptionsList: true,searchValue: value, searchFunction: func});
	}
	onChange = (event) => {
		var value = event.currentTarget.id;
		if(this.props.id !== undefined && this.props.onChange !== undefined){
			this.props.onChange(this.props.id, value, false);
		}
		this.setState({showOptionsList: false, searchValue: this.props.options.find(obj => obj.value.toString() === value.toString()).label});
	}
	render() {
		//console.log(this.props.required)
		var options = this.props.options;
		if((this.props.required === undefined || this.props.required === false) && options.find(item => item.label === '') === undefined){
			options.unshift({label: '', value: ''})
		}
		return(
			<div style={this.props.style !== undefined ? this.props.style : {}} className={this.state.showOptionsList === true ? this.props.show !== undefined && this.props.show === 'up' ? 'select select--listShowed select--listShowedUp' : 'select select--listShowed' : 'select'}>
				<div
					className="input"
				>
					<Input
						id="search"
						style={{
							borderRadius: "8px 0px 0px 8px",
							borderRightWidth: '0px' 
						}}
						label={this.props.label !== undefined ? this.props.label : ''}
						placeHolder={this.props.placeHolder !== undefined ? this.props.placeHolder : ''}
						value={
							Array.isArray(this.props.options) && this.props.options.find(obj => obj.value === this.props.value) !== undefined ?
								this.props.options.find(obj => obj.value === this.props.value).label :
								this.state.searchValue
							}
						onChange={this.onChangeInput}
						disabled={this.props.disabled}
					/>
				</div>
				<div
					className={this.props.label !== undefined && this.props.label !== '' ? "actions actions--label" : "actions"}
					onClick={this.props.disabled !== undefined && this.props.disabled ? function(){} : this.onToggleShowOptionsList}
				>
					{this.state.showOptionsList ? (<BsChevronUp />) : (<BsChevronDown />)}
				</div>
				<div
					className={this.state.showOptionsList === true ? 'list list--listShowed' : 'list'}
				>
					<div
						className='box'
					>
						{Array.isArray(options) && options.filter(this.state.searchFunction).map((option, index) =>
							<div
								id={option.value}
								key={index}
								className='item'
								onClick={this.onChange}
							>
								{option.label}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default Select;