import React from 'react';
import Table from '../../modules/react-table/src/index.js';
import Input from '../../modules/react-input/src/index.js';
import Select from '../../modules/react-select/src/index.js';
import '../../styles/layouts/materialsCategoryAdmin/materialsCategoryAdmin.scss';
import SubNav from './subnav';


import { connect } from 'react-redux'

class MaterialsCategoryLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			onCopy: false,
			view: 'split',
			selectedItems: {},
			item: {MaterialCategoryID:'', MaterialClassID:'', MaterialTypeID:'', MaterialCategoryCode:'', MaterialCategoryDescription:''},
		};
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onCopy = () => {
		this.state.onCopy === false && this.setState({onCopy: true})
	}
	onCancelCopy = () => {
		this.state.onCopy && this.setState({onCopy: false})
	}
	onSave = () => {
		if(this.state.item.id === ''){
			const action = { type: "create materialCategory", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		else{
			const action = { type: "save materialCategory", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (items) => {
		if(this.state.onEdit){
			if(this.state.onCopy){
				var item = this.state.item;
				item.MaterialClassID = Object.values(items)[0].MaterialClassID;
				item.MaterialTypeID = Object.values(items)[0].MaterialTypeID;
				item.MaterialCategoryCode = Object.values(items)[0].MaterialCategoryCode;
				item.MaterialCategoryDescription = Object.values(items)[0].MaterialCategoryDescription;
				this.setState({item: item})
			}
			else{
				if(window.confirm('Do you really discard change ?'))
				{
					this.setState({item: Object.values(items)[0], onEdit: false})
				}
			}
		}
		else{
			this.setState({item: Object.values(items)[0]})
		}
	}
	render() {
			var that = this;
			var materialsClasses_options = [];
			if(this.props.dataReducer.materialsClasses !== undefined){
				Object.values(this.props.dataReducer.materialsClasses).forEach(function(element,index){
					materialsClasses_options.push({label: element.ShortDescription, value: element.id})
				});
			}
			var materialsTypes_options = [];
			if(this.props.dataReducer.materialsTypes !== undefined){
				Object.values(this.props.dataReducer.materialsTypes).forEach(function(element,index){
					materialsTypes_options.push({label: element.Description, value: element.id})
				});
			}
			return(
				<div className='materialsAdmin'>
					<SubNav
						pageState={this.state}
						onEdit={this.onEdit}
						onCopy={this.onCopy}
						onCancelCopy={this.onCancelCopy}
						onSave={this.onSave}
						onCancel={this.onCancel}
						onChangeView={this.onChangeView}
					/>
					<div className={'users-content users-content--'+this.state.view}>
						<div className={'users-content users-content--'+this.state.view+'-item'}>
						<Select
							id='MaterialClassID'
							placeHolder='MaterialClass'
							error=''
							value={this.state.item.MaterialClassID}
							options={materialsClasses_options}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Select
							id='MaterialTypeID'
							placeHolder='MaterialType'
							error=''
							value={this.state.item.MaterialTypeID}
							options={materialsTypes_options}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='MaterialCategoryCode'
							label='MaterialCategoryCode'
							error=''
							value={this.state.item.MaterialCategoryCode}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						<Input
							id='MaterialCategoryDescription'
							label='MaterialCategoryDescription'
							error=''
							value={this.state.item.MaterialCategoryDescription}
							disabled={!this.state.onEdit}
							onChange={this.onChange}
						/>
						</div>
						<div className={'users-content users-content--'+this.state.view+'-list'}>
							<Table
								headers={
									[
										{
											label: 'MaterialClass',
											field: 'MaterialClassID',
											width: '150px',
											type: 'render',
											props: {
												render: function(item){
													return that.props.dataReducer.materialsClasses[item.MaterialClassID].ShortDescription;
												}
											}
										},
										{
											label: 'MaterialType',
											field: 'MaterialTypeID',
											width: '150px',
											type: 'render',
											props: {
												render: function(item){
													return that.props.dataReducer.materialsTypes[item.MaterialTypeID].Description;
												}
											}
										},
										{
											label: 'MaterialCategoryCode',
											field: 'MaterialCategoryCode',
											width: '150px',
										},
										{
											label: 'MaterialCategoryDescription',
											field: 'MaterialCategoryDescription',
											width: '150px',
										}
									]
								}
								data={
									Object.values(this.props.dataReducer.materialsCategories)
								}
								onSelectionChange={function(selection){
									that.onSelectItem(selection)
								}}
							/>
						</div>
					</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(MaterialsCategoryLayout);
