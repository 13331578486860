import React from 'react';
import Table from '../../modules/react-table/src/index.js';
import Input from '../../modules/react-input/src/index.js';
import Select from '../../modules/react-select/src/index.js';
import '../../styles/layouts/articlesAdmin/articlesAdmin.scss';
import SubNav from './subnav';


import { connect } from 'react-redux'

class ArticleLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onEdit: false,
			onCopy: false,
			view: 'split',
			selectedItems: {},
			item: {ArticleNo:'', HeaderNo:'', d1:'', P:'', L1:'', L2:'', L3:'', d2:'', dn:'', L:'', a:'', Z:'', R_F:'', HandTapeType:'', DrillSize:''},
		};
		const action = { type: "get articles", value: {}, noSave: true, socket: {
			send: true
		} }
		this.props.dispatch(action);
	}
	onChange = (id,value,error) => {
		var item = this.state.item;
		item[id] = value;
		this.setState({item: item})
	}
	onEdit = () => {
		this.state.onEdit === false && this.setState({onEdit: true})
	}
	onCopy = () => {
		this.state.onCopy === false && this.setState({onCopy: true})
	}
	onCancelCopy = () => {
		this.state.onCopy && this.setState({onCopy: false})
	}
	onSave = () => {
		if(this.state.item.id === ''){
			const action = { type: "create article", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		else{
			const action = { type: "save article", value: this.state.item, noSave: true, socket: {
				send: true
			} }
			this.props.dispatch(action);
		}
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onCancel = () => {
		this.state.onEdit === true && this.setState({onEdit: false})
	}
	onChangeView = (id) => {
		this.setState({view: id})
	}
	onSelectItem = (items) => {
		if(this.state.onEdit){
			if(this.state.onCopy){
				var item = this.state.item;
				item.ArticleNo = Object.values(items)[0].ArticleNo;
				item.HeaderNo = Object.values(items)[0].HeaderNo;
				item.d1 = Object.values(items)[0].d1;
				item.P = Object.values(items)[0].P;
				item.L1 = Object.values(items)[0].L1;
				item.L2 = Object.values(items)[0].L2;
				item.L3 = Object.values(items)[0].L3;
				item.d2 = Object.values(items)[0].d2;
				item.dn = Object.values(items)[0].dn;
				item.L = Object.values(items)[0].L;
				item.a = Object.values(items)[0].a;
				item.Z = Object.values(items)[0].Z;
				item.R_F = Object.values(items)[0].R_F;
				item.HandTapeType = Object.values(items)[0].HandTapeType;
				item.DrillSize = Object.values(items)[0].DrillSize;
				this.setState({item: item})
			}
			else{
				if(window.confirm('Do you really discard change ?'))
				{
					this.setState({item: Object.values(items)[0], onEdit: false})
				}
			}
		}
		else{
			this.setState({item: Object.values(items)[0]})
		}
	}
	render() {
			var that = this;
			var headers_options = [];
			if(this.props.dataReducer.headers !== undefined){
				Object.values(this.props.dataReducer.headers).forEach(function(element,index){
					headers_options.push({label: element.HeaderNo, value: element.HeaderNo})
				});
			}
			return(
				<div className='articlesAdmin'>
					<SubNav
						pageState={this.state}
						onEdit={this.onEdit}
						onCopy={this.onCopy}
						onCancelCopy={this.onCancelCopy}
						onSave={this.onSave}
						onCancel={this.onCancel}
						onChangeView={this.onChangeView}
					/>
					<div className={'articlesAdmin-content articlesAdmin-content--'+this.state.view}>
						<div className={'articlesAdmin-content articlesAdmin-content--'+this.state.view+'-item'}>
							<Select
								id='HeaderNo'
								placeHolder='HeaderNo'
								error=''
								value={this.state.item.HeaderNo}
								options={headers_options}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input			
								id='ArticleNo'
								label='ArticleNo'
								error=''
								value={this.state.item.ArticleNo}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							
							<Input
								id='d1'
								label='d1'
								error=''
								value={this.state.item.d1}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='P'
								label='P'
								error=''
								value={this.state.item.P}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='L1'
								label='l1'
								error=''
								value={this.state.item.L1}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='L2'
								label='l2'
								error=''
								value={this.state.item.L2}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='L3'
								label='l3'
								error=''
								value={this.state.item.L3}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='d2'
								label='d2'
								error=''
								value={this.state.item.d2}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='dn'
								label='dn'
								error=''
								value={this.state.item.dn}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='L'
								label='l'
								error=''
								value={this.state.item.L}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='a'
								label='a'
								error=''
								value={this.state.item.a}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='Z'
								label='z'
								error=''
								value={this.state.item.Z}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='R_F'
								label='R_F'
								error=''
								value={this.state.item.R_F}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='HandTapeType'
								label='HandTapeType'
								error=''
								value={this.state.item.HandTapeType}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
							<Input
								id='DrillSize'
								label='DrillSize'
								error=''
								value={this.state.item.DrillSize}
								disabled={!this.state.onEdit}
								onChange={this.onChange}
							/>
						</div>
						<div className={'articlesAdmin-content articlesAdmin-content--'+this.state.view+'-list'}>
						<Table
							headers={[
								{
									label: 'ArticleNo',
									field: 'ArticleNo'
								},
								{
									label: 'HeaderNo',
									field: 'HeaderNo'
								},
								{
									label: 'Stock',
									field: 'Stock',
									props: {
										'suffix': ''
									}
								},
								{
									label: 'Price',
									field: 'Price',
									type: 'money',
									props: {
										'suffix': ''
									}
								},
								{
									label: 'd1',
									field: 'd1'
								},
								{
									label: 'P',
									field: 'P'
								},
								{
									label: 'ThreadType',
									field: 'ThreadType'
								},
								{
									label: 'l1',
									field: 'L1'
								},
								{
									label: 'l2',
									field: 'L2'
								},
								{
									label: 'l3',
									field: 'L3'
								},
								{
									label: 'd2',
									field: 'd2'
								},
								{
									label: 'dn',
									field: 'dn'
								},
								{
									label: 'l',
									field: 'L'
								},
								{
									label: 'a',
									field: 'a'
								},
								{
									label: 'z',
									field: 'Z'
								},
								{
									label: 'R_F',
									field: 'R_F'
								},
								{
									label: 'HandTapType',
									field: 'R_F'
								},
								{
									label: 'DrillSize',
									field: 'DrillSize'
								},
								{
									label: 'IsOnLine',
									field: 'IsOnLine',
									type: 'bool'
								},
								{
									label: 'IsOnPaperCatalog',
									field: 'IsOnPaperCatalog',
									type: 'bool'
								},
							]}

							data={
								Object.values(this.props.dataReducer.articles)
							}
							onSelectionChange={function(selection){
								that.onSelectItem(selection)
							}}
						/>
						</div>
					</div>
        </div>
			);
	}
}
const StateToProps = (state) => {
  return state
}
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}
export default connect(StateToProps,DispatchToProps)(ArticleLayout);
