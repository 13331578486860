import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
import localforage from 'localforage';
import { io } from "socket.io-client";

import {wsMiddleWare} from './wsMiddleWare';
import rootReducer from './reducers/root'
const host = 'ws://manager.tanoi.com:9000';
const socket = io(host, { query: {token: sessionStorage.getItem('token')} });
const persistConfig = {
  key: 'root',
  storage: localforage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

socket.on("connect", () => {
	//console.info('connected')
});
socket.on("connect_error", (error) => {
	console.error("Error");
	console.error(error);

	store.dispatch({type: 'SIGNOUT'})
	store.dispatch({type: 'DISCONNECT'})
});
socket.on("disconnect", (reason) => {
	console.error(reason)
	store.dispatch({type: 'SIGNOUT'})
	store.dispatch({type: 'DISCONNECT'})
});
socket.on("message", data => {
	if(data.type !== undefined){
		store.dispatch(data)
	}
});
socket.on("update", data => {

});

var store = createStore(
	persistedReducer,
	{sessionReducer: {connected: false, loged: false}},
	applyMiddleware(wsMiddleWare(socket))
)
var persistor = persistStore(store);
// eslint-disable-next-line
export default () => {
  return { store, persistor }
}
