const initialState = {

}
function dataReducer(state = initialState, action) {
	let nextState
	switch (action.type) {
		case 'CREATE_ACCOUNT':
			nextState = {
				...state,
				accounts: {...state.accounts, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_ACCOUNTS':
			nextState = {
				...state,
				accounts: action.value,
			}
			return nextState || state
		case 'CREATE_HEADER':
			nextState = {
				...state,
				headers: {...state.headers, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_HEADERS':
			nextState = {
				...state,
				headers: action.value,
			}
			return nextState || state
		case 'CREATE_HEADER_POSITION':
			nextState = {
				...state,
				headersPositions: {...state.headersPositions, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_HEADERS_POSITIONS':
			nextState = {
				...state,
				headersPositions: action.value,
			}
			return nextState || state
		case 'CREATE_POSITION':
			nextState = {
				...state,
				positions: {...state.positions, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_POSITIONS':
			nextState = {
				...state,
				positions: action.value,
			}
			return nextState || state
		case 'CREATE_ARTICLE':
			nextState = {
				...state,
				articles: {...state.articles, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_ARTICLES':
			nextState = {
				...state,
				articles: action.value,
			}
			return nextState || state
		case 'CREATE_MATERIAL':
			nextState = {
				...state,
				materials: {...state.materials, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_MATERIALS':
			nextState = {
				...state,
				materials: action.value,
			}
			return nextState || state
		case 'CREATE_MATERIAL_CATEGORY':
			nextState = {
				...state,
				materialsCategories: {...state.materialsCategories, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_MATERIALS_CATEGORIES':
			console.log("OK");
			nextState = {
				...state,
				materialsCategories: action.value,
			}
			return nextState || state
		case 'CREATE_MATERIAL_TYPE':
			nextState = {
				...state,
				materialsTypes: {...state.materialsTypes, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_MATERIALS_TYPES':
			nextState = {
				...state,
				materialsTypes: action.value,
			}
			return nextState || state
		case 'CREATE_MATERIAL_CLASSE':
			nextState = {
				...state,
				materialsClasses: {...state.materialsClasses, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_MATERIALS_CLASSES':
			nextState = {
				...state,
				materialsClasses: action.value,
			}
			return nextState || state
		case 'CREATE_TECHNICAL_CARACTERISTIC':
			nextState = {
				...state,
				technicalCaracteristics: {...state.technicalCaracteristics, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_TECHNICALS_CARACTERISTICS':
			nextState = {
				...state,
				technicalCaracteristics: action.value,
			}
			return nextState || state
		case 'CREATE_ICON':
			nextState = {
				...state,
				technicalCaracteristics: {...state.technicalCaracteristics, [action.value.id]: action.value},
			}
			return nextState || state
		case 'UPDATE_ICONS':
			nextState = {
				...state,
				icons: action.value,
			}
			return nextState || state
		default:
			return state
	}
}
export default dataReducer
